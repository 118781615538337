import { Suspense } from 'react';
import { Route, type RouteProps } from 'react-router-dom';

interface PublicRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PublicRoute = ({ component: Component, ...routeProps }: PublicRouteProps) => (
  <Route
    {...routeProps}
    render={(props) => (
      <Suspense fallback={null}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

export default PublicRoute;
