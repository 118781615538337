/// <reference types="vite/client" />

const config = {
  apiURL: import.meta.env.VITE_API_URL,
  chatApiURL: import.meta.env.VITE_CHAT_API_URL,
  shopify: {
    clientId: import.meta.env.VITE_SHOPIFY_CLIENT_ID,
    redirect: import.meta.env.VITE_SHOPIFY_REDIRECT,
    scope:
      'write_orders,write_draft_orders,write_fulfillments,write_inventory,write_products,write_assigned_fulfillment_orders,write_third_party_fulfillment_orders,write_returns',
  },
  hellosign: {
    clientId: import.meta.env.VITE_HELLOSIGN_CLIENT_ID,
    skipDomainVerification: import.meta.env.VITE_HELLOSIGN_SKIP_DOMAIN_VERIFICATION === 'true',
  },
  isDevelopment: import.meta.env.VITE_ENV === 'development',
  isStaging: import.meta.env.VITE_ENV === 'staging',
  isProduction: import.meta.env.VITE_ENV === 'production',
  isTest: import.meta.env.VITE_ENV === 'test',
  pathBasename: import.meta.env.VITE_BASENAME,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
};

export default config;
