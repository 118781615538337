import styled from '@emotion/styled';
import Grid from '@react-css/grid';
import useAiChartRef from 'hooks/useAiChartRef';
import { Remarkable } from 'remarkable';
import { AiIconWrapper, MessageContent } from 'storybookConfig/stories/species/Chat/Shared.styles';
import type { ChatMessage } from 'types/models/conversation';

const md = new Remarkable({
  linkTarget: '_blank',
});

const Wrapper = styled.div`
  max-width: 80%;
`;

const StyledMessageContent = styled(MessageContent)`
  background-color: ${({ theme }) => theme.color.blue100};
  color: ${({ theme }) => theme.color.bodyTextPrimary};
`;

interface ChatAiMessageProps {
  content: ChatMessage['content'];
}

const ChatAiMessage = ({ content }: ChatAiMessageProps) => {
  const contentRef = useAiChartRef(content);

  return (
    <Wrapper>
      <Grid columns="40px 1fr" gap="8px" alignItemsStart>
        <AiIconWrapper />
        <Grid.Item>
          <StyledMessageContent
            ref={contentRef}
            dangerouslySetInnerHTML={{
              __html: md.render(content),
            }}
          />
        </Grid.Item>
      </Grid>
    </Wrapper>
  );
};

export default ChatAiMessage;
