import styled from '@emotion/styled';
import { forwardRef } from 'react';
import CommerceTypeAvatar, {
  COMMERCE_TYPE_AVATAR_SIZE,
} from 'storybookConfig/stories/cells/Avatar/CommerceTypeAvatar';
import Body from 'storybookConfig/stories/molecules/Body';
import Icon from 'storybookConfig/stories/molecules/Icon';
import { CommerceType, type Company } from 'types/models/company';
import { isUserMembership, type UserMembership } from 'types/models/user';

const convertMembershipToCompanyPartial = (membership: UserMembership): Partial<Company> => ({
  commerceType: membership.commerceType,
  name: membership.companyName,
  url: membership.url,
});

const Wrapper = styled.button<Partial<CompanyProfileProps>>`
  all: unset;
  display: ${({ isFullWidth }) => (isFullWidth ? 'flex' : 'inline-flex')};
  gap: 8px;
  align-items: center;
  border-radius: ${COMMERCE_TYPE_AVATAR_SIZE};
  background: ${({ theme }) => theme.color.gray100};
  padding-right: 16px;
  max-width: 100%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0; // This is needed to allow truncation
`;

interface CompanyProfileProps {
  company: Partial<Company> | UserMembership | null;
  isFullWidth?: boolean;
  hasDropdownArrow?: boolean;
  onClick?: () => void;
}

const CompanyProfile = forwardRef<HTMLButtonElement, CompanyProfileProps>(
  ({ company, isFullWidth, hasDropdownArrow, onClick }: CompanyProfileProps, ref) => {
    const data = isUserMembership(company) ? convertMembershipToCompanyPartial(company) : company;

    return (
      <Wrapper ref={ref} isFullWidth={isFullWidth} onClick={onClick}>
        <CommerceTypeAvatar commerceType={data?.commerceType || CommerceType.None} />

        <TextWrapper>
          <Body variant="Body/Body Small" truncate>
            {data?.name}
          </Body>
          <Body variant="Chips/Chip Text Small" truncate>
            {data?.url}
          </Body>
        </TextWrapper>

        {hasDropdownArrow && <Icon name="arrow_drop_down" />}
      </Wrapper>
    );
  }
);

export default CompanyProfile;
