import styled from '@emotion/styled';

import type { LinkProps } from 'react-router-dom';
import type { MaterialSymbolOptions } from 'storybookConfig/mixins/materialSymbol';
import materialSymbol from 'storybookConfig/mixins/materialSymbol';

export interface IconProps extends MaterialSymbolOptions {
  /**
   * Optional text to display alongside the icon.
   */
  children?: React.ReactNode;

  /**
   * The HTML element to render the button as
   */
  as?: React.ElementType;

  /**
   * an optional route to navigate to if cast as a Link
   */
  to?: LinkProps['to'];

  /**
   * an optional article route to navigate to if cast as a SupportLink
   */
  article?: string;

  /**
   * an optional target to break out into tab to if cast as a Link
   */
  target?: string;
}

/**
 * Provides a container for the styles supplied by the `materialSymbol` mixin.
 * It supports basic styling for accompanying text, but usage of the `materialSymbol`
 * mixin inside of another, dedicated component is recommended for more complex usage.
 *
 * The `name` prop should be a Google Material Symbol value, which you can find by visiting
 * [this page](https://fonts.google.com/icons). Find the icon you want to use, click it, and,
 * in the sidebar, copy and paste by snake-case slug shown in the "Inserting the icon" section.
 */
const Icon = styled.span<IconProps>`
  all: unset;
  display: inline-flex;
  align-items: center;
  gap: 8px;

  ${({ name, position, color, weight, fill, grade, opticalSize, size, additionalStyling }) =>
    materialSymbol({
      name,
      position,
      color,
      weight,
      fill,
      grade,
      opticalSize,
      size,
      additionalStyling,
    })}
`;

export default Icon;
