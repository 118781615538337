import Flex from '@react-css/flex';
import { cloneElement, isValidElement } from 'react';
import Body from 'storybookConfig/stories/molecules/Body';
import Label from 'storybookConfig/stories/molecules/Label';
import Select from 'storybookConfig/stories/molecules/Select';

const assignIdProp = (props: any, type: any, id: string) => {
  // Select elements require an inputId prop instead of an id prop.
  if (type === Select) {
    return { ...props, inputId: id };
  }

  return { ...props, id };
};

interface FormFieldProps {
  /**
   * The label text for the input.
   */
  labelText: string;
  /**
   * The id of the input.
   */
  id: string;
  /**
   * The input to wrap.
   */
  children: React.ReactNode;
  /**
   * The error text to display.
   */
  errorText?: string;
  /**
   * The description text to display.
   */
  descriptionText?: string;
  /**
   * Whether the input is required.
   */
  isRequired?: boolean;
}

/**
 * A simple layout wrapping an Input with a label and optional error text.
 */
const FormField = ({
  labelText,
  id,
  children,
  errorText,
  isRequired,
  descriptionText,
}: FormFieldProps) => {
  const field = isValidElement(children)
    ? cloneElement(children, assignIdProp(children.props, children.type, id))
    : children;

  return (
    <Flex column gap="8px" alignItemsStart>
      <Label htmlFor={id} isRequired={isRequired} hasError={!!errorText}>
        {labelText}
      </Label>

      {field}

      {errorText && (
        <Body variant="Body/Body Small" color="error500">
          {errorText}
        </Body>
      )}

      {descriptionText && (
        <Body variant="Body/Body Small" color="bodyTextSecondary">
          {descriptionText}
        </Body>
      )}
    </Flex>
  );
};

export default FormField;
