// Provides styles to remove the browser-default styling of a button.
import { css } from '@emotion/react';

// This is useful boilerplate for creating custom buttons.
const resetButtonStyles = css`
  background: none;
  padding: 0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: inherit;
  font-weight: inherit;
  cursor: pointer;
`;

export default resetButtonStyles;
