import { css, Global } from '@emotion/react';
import theme from './theme';

import typography from 'storybookConfig/mixins/typography';
import { BUTTON_SIZE } from 'storybookConfig/stories/species/Chat/FloatingChat/FloatingChatButton';

const globalStyles = css`
  html {
    box-sizing: border-box;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body {
    margin: 0;
    height: 100vh;
  }

  // App container wraps the entire app

  .app-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    overflow-y: scroll;
  }

  // Additional portal containers
  .alerts-container {
    position: relative;
    z-index: 1051; // React modals are 1050
  }

  // Helpers

  .visuallyhidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    width: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }

  .fixFirstTdPadding.fixFirstTdPadding.fixFirstTdPadding {
    // Useful for overriding the theme's '.card-table tbody td:first-of-type'
    // padding when using a 'rowSpan' prop on a 'td'.
    padding-left: 16px !important;
  }
`;

const typographyStyles = css`
  body {
    ${typography('Body/Regular')}
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    background-color: ${theme.color.gray100};
    color: ${theme.color.gray600};
    background-color: ${theme.color.gray200};
    display: inline-block;
    padding: 0 0.4em;
    border-radius: 0.25rem;
  }
`;

const bootstrapStylesOverride = css`
  // Override Theme Variables

  :root {
    // There is no direct replacement for indigo, purple, orange, or pink.
    // We're choosing not to override the default bootstrap breakpoints.
    --blue: ${theme.color.blue500};
    --red: ${theme.color.error500};
    --yellow: ${theme.color.warning500};
    --green: ${theme.color.success500};
    --teal: ${theme.color.green500};
    --cyan: ${theme.color.info500};
    --white: ${theme.color.white};
    --gray: ${theme.color.gray500};
    --gray-dark: ${theme.color.gray700};
    --primary: ${theme.color.blue500};
    --secondary: ${theme.color.gray500};
    --success: ${theme.color.success500};
    --info: ${theme.color.info500};
    --warning: ${theme.color.warning500};
    --danger: ${theme.color.error500};
    --light: ${theme.color.gray100};
    --dark: ${theme.color.gray700};

    --font-family-sans-serif: ${theme.font.primary};
    --font-family-monospace: ${theme.font.monospace};
  }

  // App Wrapper

  .main-content {
    flex: 1;
    overflow-x: auto;

    background-color: ${theme.color.gray100};
  }

  .navbar-vertical:not([style*='display: none']) ~ .main-content .container-fluid.container-fluid {
    padding-left: 24px !important;
    padding-right: ${BUTTON_SIZE + 8}px !important;
  }

  // Dropdown Menus

  .DropdownMenu__no-minw.DropdownMenu__no-minw {
    min-width: inherit;

    .dropdown-item {
      padding: 0.374rem 0.8rem;
    }
  }

  // Override Feather iconography for Dropdown Toggles
  // Present on page length for pagination
  .dropdown-toggle::after {
    font-family: ${theme.font.icon};
    content: 'expand_more';
  }

  // Typography

  h1,
  .h1,
  .display-1 {
    ${typography('Headings/H1')}
  }

  h2,
  .h2,
  .display-2 {
    ${typography('Headings/H2')}
  }

  h3,
  .h3,
  .display-3 {
    ${typography('Headings/H3')}
  }

  h4,
  .h4,
  .display-4 {
    ${typography('Headings/H4')}
  }

  h5,
  .h5 {
    ${typography('Body/Header')}
  }

  h6,
  .h6 {
    ${typography('Body/Caption')}
  }

  .text-muted {
    color: #59759b !important;
  }

  a {
    color: ${theme.color.bodyTextLinks};
    text-decoration: none;

    &:hover {
      color: ${theme.color.bodyTextLinksHover};
      text-decoration: underline;
    }
  }

  // Forms

  .form-label {
    font-size: 11px;
    line-height: 1;
    font-weight: 500;
    margin-bottom: 8px;
    text-transform: uppercase;
    font-family: ${theme.font.secondary};
  }

  .form-group {
    margin-bottom: 16px;
  }

  .invalid-feedback {
    display: block;
  }
`;

const GlobalStyles = () => {
  return (
    <>
      <Global styles={globalStyles} />
      <Global styles={typographyStyles} />
      <Global styles={bootstrapStylesOverride} />
    </>
  );
};

export default GlobalStyles;
