import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import { createContext, useContext, useState } from 'react';
import TertiaryButton from 'storybookConfig/stories/molecules/Button/TertiaryButton';

interface AccordionOptions {
  initialOpen?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

const useAccordion = ({
  initialOpen = false,
  isOpen: controlledIsOpen,
  setIsOpen: setControlledIsOpen,
}: AccordionOptions) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const isOpen = controlledIsOpen ?? uncontrolledOpen;
  const setIsOpen = setControlledIsOpen ?? setUncontrolledOpen;

  return {
    isOpen,
    setIsOpen,
    toggle: () => setIsOpen(!isOpen),
  };
};

type ContextType = ReturnType<typeof useAccordion> | null;

const AccordionContext = createContext<ContextType>(null);

const useAccordionContext = () => {
  const context = useContext(AccordionContext);

  if (context == null) {
    throw new Error('Accordion components must be wrapped in <Accordion.Root />');
  }

  return context;
};

const HeadWrapper = styled.div`
  padding: 8px 0;
`;

interface HeadProps {
  text: string;
}

const Head = ({ text }: HeadProps) => {
  const { isOpen, toggle } = useAccordionContext();

  return (
    <HeadWrapper>
      <Flex justifyEnd>
        <TertiaryButton
          $removePadding="right"
          $iconName={isOpen ? 'expand_less' : 'expand_more'}
          $iconAfter
          onClick={toggle}
        >
          {text}
        </TertiaryButton>
      </Flex>
    </HeadWrapper>
  );
};

const Body = ({ children }: { children: React.ReactNode }) => {
  const { isOpen } = useAccordionContext();

  return isOpen ? children : null;
};

const RootWrapper = styled.div<Pick<AccordionOptions, 'isOpen'>>`
  border-top: 1px dashed ${({ theme }) => theme.color.gray200};
`;

const Root = ({ children, ...options }: { children: React.ReactNode } & AccordionOptions) => {
  const accordion = useAccordion(options);

  return (
    <RootWrapper isOpen={accordion.isOpen}>
      <AccordionContext.Provider value={accordion}>
        <Flex column gap="16">
          {children}
        </Flex>
      </AccordionContext.Provider>
    </RootWrapper>
  );
};

export default {
  Root,
  Head,
  Body,
};
