import styled from '@emotion/styled';
import Flex from '@react-css/flex';

const Wrapper = styled.div`
  padding: 16px;
`;

interface SidebarProps {
  children: React.ReactNode;
  footerUi?: React.ReactNode;
  className?: string;
}

/**
 * Provides layout for a sidebar with a main content area and an optional footer.
 * Expects to be inside a container.
 */
const Sidebar = ({ children, footerUi, className }: SidebarProps) => (
  <Wrapper className={className}>
    <Flex column gap="8px" style={{ height: '100%' }}>
      <Flex.Item grow={1}>{children}</Flex.Item>
      {footerUi}
    </Flex>
  </Wrapper>
);

export default Sidebar;
