import styled from '@emotion/styled';
import { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';

import { useAppDispatch, useAppSelector } from 'store';
import { selectActiveAlerts } from 'store/selectors/alerts';
import { removeAlert } from 'store/slices/alerts';
import Alert, { ContentWrapper } from 'storybookConfig/stories/cells/Alert';

export const ALERT_CLOSE_DURATION = 5000;
export const ALERT_TIMERS_BY_ID: Record<string, number> = {};

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 400px;
  gap: 8px;
  top: 16px;
  right: 16px;

  ${ContentWrapper} {
    max-width: 230px;
  }
`;

/**
 * Manages the display of a group of alerts fixed to the top-right area of the app.
 * Appended to its own root div outside of the app via Portaling, for positioning purposes.
 */
const AlertQueue = () => {
  const dispatch = useAppDispatch();

  // State

  const activeAlertData = useAppSelector(selectActiveAlerts);

  // Helpers

  const handleAlertClose = useCallback(
    (id: string) => {
      dispatch(removeAlert(id));
      delete ALERT_TIMERS_BY_ID[id];
    },
    [dispatch]
  );

  // Side Effects

  useEffect(() => {
    const untimedAlerts = activeAlertData.filter(({ id }) => ALERT_TIMERS_BY_ID[id] === undefined);

    untimedAlerts.forEach(({ id }) => {
      ALERT_TIMERS_BY_ID[id] = window.setTimeout(() => handleAlertClose(id), ALERT_CLOSE_DURATION);
    });
  }, [activeAlertData, handleAlertClose]);

  // Render

  return createPortal(
    <Wrapper>
      {activeAlertData.map(({ id, data: { type, title, body } }) => (
        <Alert
          key={id}
          type={type}
          title={title}
          hasDropShadow
          onClose={() => handleAlertClose(id)}
          role="alertdialog"
          aria-label={`${type} alert`}
        >
          {body}
        </Alert>
      ))}
    </Wrapper>,
    window.document.getElementById('alerts-root') as HTMLElement
  );
};

export default AlertQueue;
