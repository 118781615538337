import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import type { ReactNode } from 'react';
import typography from 'storybookConfig/mixins/typography';

const Wrapper = styled.label<{ hasError?: boolean }>`
  ${typography('Body/Header')};

  ${({ theme, hasError }) => css`
    color: ${hasError ? theme.color.error500 : theme.color.bodyTextSecondary};
  `}
`;

const RequiredIndicator = styled.span`
  color: ${({ theme }) => theme.color.error500};
`;

const UpdatedIndicator = styled.div`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.warning500};
`;

export interface LabelProps extends React.ComponentProps<typeof Wrapper> {
  /**
   * When true, label will be accompanied by an updated indicator
   */
  hasEdits?: boolean;
  /**
   * When true, label will be displayed in an error state
   */
  hasError?: boolean;
  /**
   * When true, label will be accompanied by an required indicator
   */
  isRequired?: boolean;
  /**
   * Enforced for a11y. Must match `id` attribute of an associated input.
   */
  htmlFor: string;
  /**
   * The label's content
   */
  children: ReactNode;
}

/**
 * A styled Label element primarily used to accompany text inputs.
 */
const Label = ({ children, htmlFor, isRequired, hasEdits, hasError, ...rest }: LabelProps) => (
  <Wrapper htmlFor={htmlFor} hasError={hasError} {...rest}>
    <Flex inline alignItemsCenter gap="4px">
      {children}

      {isRequired && <RequiredIndicator>*</RequiredIndicator>}

      {hasEdits && (
        <Flex.Item>
          <UpdatedIndicator />
        </Flex.Item>
      )}
    </Flex>
  </Wrapper>
);

export default Label;
