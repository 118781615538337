import styled from '@emotion/styled';
import { noop } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import ComboInput from 'storybookConfig/stories/cells/ComboInput';
import Icon from 'storybookConfig/stories/molecules/Icon';

export const CHAT_FORM_MIN_HEIGHT = 78;

const Wrapper = styled.form`
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  overflow-y: auto;
  min-height: ${CHAT_FORM_MIN_HEIGHT}px;
  background-color: rgba(255, 255, 255, 0.75);

  ${ComboInput.Input} {
    overflow-y: auto;
    max-height: 150px;
  }
`;

export interface ChatFormValues {
  question: string;
}

interface ChatFormProps extends React.ComponentProps<typeof Wrapper> {
  isDisabled?: boolean;
  onFormSubmit: (values: ChatFormValues) => void;
  inputValue?: string;
}

const ChatForm = ({
  isDisabled,
  onFormSubmit,
  inputValue = '',
  ...wrapperProps
}: ChatFormProps) => {
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const { handleSubmit, register, setValue, reset, formState } = useForm<ChatFormValues>({
    mode: 'onChange',
    defaultValues: {
      question: '',
    },
  });

  /**
   * Event Handlers
   */

  const handleFormSubmit = useCallback(
    (values: ChatFormValues) => {
      onFormSubmit(values);
      reset();
      // Since the input is contentEditable we need to handle resetting differently
      if (inputRef.current) inputRef.current.innerText = '';
    },
    [onFormSubmit, reset]
  );

  /**
   * Side Effects
   */

  // Setup the keyboard shortcut for Enter key submission
  // Does not submit if the shift key is also being held down
  useEffect(() => {
    if (!isInputFocused) return noop;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSubmit(handleFormSubmit)();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onFormSubmit, handleSubmit, isInputFocused, handleFormSubmit]);

  // Preload the inputRef with the current value
  useEffect(() => {
    if (inputRef.current) inputRef.current.innerText = inputValue;
  }, [inputValue]);

  /**
   * Render
   */

  return (
    <Wrapper onSubmit={handleSubmit(handleFormSubmit)} {...wrapperProps}>
      <ComboInput isFullWidth>
        <ComboInput.Input
          as="div"
          contentEditable
          disabled={isDisabled || formState.isSubmitting}
          {...register('question', { required: true })}
          aria-label="Type your message"
          onBlur={() => {
            setIsInputFocused(false);
          }}
          onFocus={(event: React.FocusEvent<HTMLDivElement>) => {
            // As soon as a user focuses on the input, we set the inputRef to the current target
            // This way we can clear the input when the form is submitted
            inputRef.current = event.currentTarget;
            setIsInputFocused(true);
          }}
          onInput={(event: React.FormEvent<HTMLDivElement>) => {
            // Since this is contentEditable we need to handle input differently
            setValue('question', event.currentTarget.innerText, {
              shouldDirty: true,
              shouldValidate: true,
            });
          }}
        />
        <ComboInput.Button
          aria-label="Submit message"
          type="submit"
          disabled={isDisabled || formState.isSubmitting}
        >
          <Icon name="send" />
        </ComboInput.Button>
      </ComboInput>
    </Wrapper>
  );
};

export default ChatForm;
