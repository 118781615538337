import { css } from '@emotion/react';
import styled from '@emotion/styled';
import typography from 'storybookConfig/mixins/typography';
import Icon from 'storybookConfig/stories/molecules/Icon';
import type { ThemeColor } from 'storybookConfig/stories/theme';

interface AvatarProps {
  iconName: string;
  size?: string;
  color?: ThemeColor;
  backgroundColor?: ThemeColor;
  borderColor?: ThemeColor;
  text?: string;
}

const Wrapper = styled.div<Omit<AvatarProps, 'iconName'>>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-transform: capitalize;
  border: 1px solid;

  ${({
    theme,
    size = '40px',
    backgroundColor = 'gray200',
    color = 'gray500',
    borderColor = 'gray500',
  }) => css`
    background: ${theme.color[backgroundColor]};
    color: ${theme.color[color]};
    border-color: ${theme.color[borderColor]};
    width: ${size};
    height: ${size};
    min-width: ${size};
    border-radius: ${size};
  `}
`;

const TextWrapper = styled.span`
  ${typography('Body/Body Small')};
  font-size: 9px;
  line-height: 1;
`;

const Avatar = ({ iconName, text, color, ...wrapperProps }: AvatarProps) => {
  return (
    <Wrapper color={color} {...wrapperProps}>
      <Icon name={iconName} color={color} />
      {text && <TextWrapper>{text}</TextWrapper>}
    </Wrapper>
  );
};

export default Avatar;
