import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import type { Elevation } from 'storybookConfig/mixins/elevate';
import elevate from 'storybookConfig/mixins/elevate';
import BodyMolecule, { type BodyProps } from 'storybookConfig/stories/molecules/Body';

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  /**
   * The elevation of the card.
   */
  elevation?: Elevation | 'none';
  /**
   * Padding
   */
  padding?: string;
  /**
   * isFullWidth
   */
  $isFullWidth?: boolean;

  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Base = styled((props: CardProps) => <Flex {...props} column gap="24px" />, {
  shouldForwardProp: (prop) => !['elevation', 'padding', '$isFullWidth'].includes(prop),
})`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  padding: ${({ padding = '24px 32px' }) => padding};

  ${({ elevation = '1' }) => elevation !== 'none' && elevate(elevation)};

  ${({ $isFullWidth }) => $isFullWidth && 'width: 100%'};
`;

const Title = styled((props: BodyProps) => <BodyMolecule {...props} variant="Headings/H3" />)<{
  children: React.ReactNode;
}>``;

const Head = styled(Flex.Item)`
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray200};

  ${Title} + button {
    max-height: 24px;
  }
`;

const Body = styled(Flex.Item)``;

/**
 * A simple, visually distinct Card UI. It supports optional `Card.Head` and `Card.Body` subcomponents,
 * which can provide layout for content. If no `Card.Head` is necessary you may omit `Card.Body`.
 */
const Card = Object.assign(Base, { Head, Title, Body });

export default Card;
