import styled from '@emotion/styled';
import elevate from 'storybookConfig/mixins/elevate';
import Body from 'storybookConfig/stories/molecules/Body';
import LinkButton from 'storybookConfig/stories/molecules/Link/LinkButton';
import { BUTTON_SIZE } from 'storybookConfig/stories/species/Chat/FloatingChat/FloatingChatButton';

export const WINDOW_OFFSET = 24;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: calc(${BUTTON_SIZE}px + ${WINDOW_OFFSET}px);
  right: 0;
  width: 400px;
  height: auto;
  ${elevate('4')};
  border-radius: 8px;
  background: ${({ theme }) => theme.color.white};
  padding: 16px;

  &::after {
    content: '';
    position: absolute;
    right: 20px;
    bottom: -12px;
    border-top: 12px solid ${({ theme }) => theme.color.white};
    border-right: 12px solid transparent;
    border-left: 12px solid transparent;
    border-bottom: none;
  }
`;

interface FloatingChatPromptProps {
  onDismissClick: () => void;
}

const FloatingChatPrompt = ({ onDismissClick }: FloatingChatPromptProps) => {
  return (
    <Wrapper>
      <Body variant="Body/Regular" color="bodyTextSecondary">
        Hey! Maybe this is something I can help you with? Click this button to start a conversation!{' '}
        <LinkButton onClick={onDismissClick}>Dismiss?</LinkButton>
      </Body>
    </Wrapper>
  );
};

export default FloatingChatPrompt;
