import styled from '@emotion/styled';
import Layout from 'storybookConfig/stories/species/Layout';

const Root = styled(Layout.Root)`
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  width: 100vw;
`;

const Content = styled(Layout.Content)`
  padding: 80px 160px;
  max-width: unset;
`;

const SuperHeading = styled.h1`
  font-size: 50px;
  line-height: 60px;
  margin: 0;
  color: ${({ theme }) => theme.color.white};
`;

const Aside = styled.div`
  background: ${({ theme }) => theme.color.white};
  border-radius: 18px 0 0 16px;
  padding: 80px;
  inset: 0 0 0 auto;
  max-height: 100vh;
  overflow-y: auto;
  max-width: 600px;
`;

const SignUpPageLayout = {
  ...Layout,
  Root,
  Content,
  Aside,
  SuperHeading,
};

export default SignUpPageLayout;
