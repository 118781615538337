import Flex from '@react-css/flex';
import Grid from '@react-css/grid';
import { useFormContext } from 'react-hook-form';
import Accordion from 'storybookConfig/stories/cells/Accordion';
import Body from 'storybookConfig/stories/molecules/Body';
import PlatformDecisionButton from 'storybookConfig/stories/molecules/Button/DecisionButton/PlatformDecisionButton';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybookConfig/stories/molecules/Button/TertiaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import SupportLink from 'storybookConfig/stories/molecules/Link/SupportLink';
import ProgressBar from 'storybookConfig/stories/organisms/ProgressBar';
import { CommerceType, Platform } from 'types/models/company';

const BuyerPlatformFields = () => {
  const { setValue, watch } = useFormContext();

  const platform = watch('platform');

  return (
    <Grid columns="1fr 1fr" gap="24px">
      <PlatformDecisionButton
        variant={Platform.Shopify}
        isActive={platform === Platform.Shopify}
        onClick={() => setValue('platform', Platform.Shopify)}
      />

      <PlatformDecisionButton
        variant={Platform.Other}
        isActive={platform === Platform.Other}
        onClick={() => setValue('platform', Platform.Other)}
      >
        Modern Dropship API
      </PlatformDecisionButton>
    </Grid>
  );
};

const SellerPlatformFIelds = () => {
  const { setValue, watch } = useFormContext();

  const platform = watch('platform');

  return (
    <Flex column gap="32px">
      <Grid columns="1fr 1fr" gap="24px">
        <PlatformDecisionButton
          variant={Platform.Shopify}
          isActive={platform === Platform.Shopify}
          onClick={() => setValue('platform', Platform.Shopify)}
        />

        <PlatformDecisionButton
          variant={Platform.WooCommerce}
          isActive={platform === Platform.WooCommerce}
          onClick={() => setValue('platform', Platform.WooCommerce)}
        />

        <PlatformDecisionButton
          variant={Platform.BigCommerce}
          isActive={platform === Platform.BigCommerce}
          onClick={() => setValue('platform', Platform.BigCommerce)}
        />

        <PlatformDecisionButton
          variant={Platform.Magento2}
          isActive={platform === Platform.Magento2}
          onClick={() => setValue('platform', Platform.Magento2)}
        />
      </Grid>

      <Accordion.Root>
        <Accordion.Head text="Additional Syncing Options" />

        <Accordion.Body>
          <Flex column gap="24px">
            <PlatformDecisionButton
              variant={Platform.CsvSftp}
              isActive={platform === Platform.CsvSftp}
              onClick={() => setValue('platform', Platform.CsvSftp)}
            />

            <PlatformDecisionButton
              variant={Platform.ExternalSftp}
              isActive={platform === Platform.ExternalSftp}
              onClick={() => setValue('platform', Platform.ExternalSftp)}
            />

            <PlatformDecisionButton
              variant={Platform.EdiSftp}
              isActive={platform === Platform.EdiSftp}
              onClick={() => setValue('platform', Platform.EdiSftp)}
            />

            <PlatformDecisionButton
              variant={Platform.EdiExternalSftp}
              isActive={platform === Platform.EdiExternalSftp}
              onClick={() => setValue('platform', Platform.EdiExternalSftp)}
            />

            <PlatformDecisionButton
              variant={Platform.Other}
              isActive={platform === Platform.Other}
              onClick={() => setValue('platform', Platform.Other)}
            >
              Manage your data within the Modern Dropship App or use our API
            </PlatformDecisionButton>
          </Flex>
        </Accordion.Body>
      </Accordion.Root>
    </Flex>
  );
};

interface PlatformSelectionProps {
  currentStep: number;
  totalSteps: number;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}

const PlatformSelection = ({
  currentStep,
  totalSteps,
  onNextButtonClick,
  onBackButtonClick,
}: PlatformSelectionProps) => {
  const { watch, reset, getValues } = useFormContext();

  const commerceType = watch('commerceType');

  return (
    <Flex column gap="48px">
      <ProgressBar totalSteps={totalSteps} completedSteps={currentStep} />

      <Flex column gap="32px">
        <Flex column gap="8px">
          <Heading variant="Headings/H3" as="h1">
            How would you like to connect to Modern Dropship?
          </Heading>

          <Body as="p">
            We sync order data every 15 minutes. Product, inventory, and pricing data is synced
            every hour.{' '}
            <SupportLink article="/integrations/supported-platforms">Learn more</SupportLink>
          </Body>
        </Flex>

        {commerceType === CommerceType.Buyer && <BuyerPlatformFields />}
        {commerceType === CommerceType.Seller && <SellerPlatformFIelds />}

        <Flex justifySpaceBetween>
          <TertiaryButton
            $iconName="navigate_before"
            $removePadding="left"
            onClick={() => {
              onBackButtonClick();

              reset({
                ...getValues(),
                platform: Platform.Shopify,
              });
            }}
          >
            Back
          </TertiaryButton>

          <PrimaryButton $iconName="navigate_next" $iconAfter onClick={onNextButtonClick}>
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PlatformSelection;
