import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import Announcements from 'components/Dashboard/LeftMenu/Announcements';
import FooterMenu from 'components/Dashboard/LeftMenu/FooterMenu';
import MainMenu from 'components/Dashboard/LeftMenu/MainMenu';
import SupportModal from 'components/Dashboard/SupportModal';
import useStagingBuild from 'hooks/useStagingBuild';
import { type ComponentProps, useCallback } from 'react';
import { matchPath, useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectCompany } from 'store/selectors/me/company';
import { selectIsSuperUser, selectUserMemberships } from 'store/selectors/me/user';
import CompanyProfile from 'storybookConfig/stories/organisms/CompanyProfile';
import Sidebar from 'storybookConfig/stories/organisms/Sidebar';
import CompanyPicker from 'storybookConfig/stories/species/CompanyPicker';
import { useBoolean } from 'usehooks-ts';
import type { SwitchCompanyParams } from 'utils/api/login';
import { switchCompany } from 'utils/api/login';

const StyledSidebar = styled((props: Omit<ComponentProps<typeof Sidebar>, 'div'>) => (
  <Sidebar
    {...props}
    className="navbar navbar-vertical fixed-left navbar-expand-md navbar-light left-menu-navbar"
  />
))`
  padding: 28px 16px 8px !important; // important to override theme
`;

const LeftMenu = () => {
  const { pathname } = useLocation();

  const company = useAppSelector(selectCompany);
  const memberships = useAppSelector(selectUserMemberships);
  const isSuperUser = useAppSelector(selectIsSuperUser);

  const supportModalVisibility = useBoolean(false);

  const prDir = useStagingBuild();

  /**
   * Helpers
   */

  const otherMemberships = memberships.filter(
    (membership) => membership.companyObjectId !== company?._id
  );

  const isActive = useCallback(
    (path: string) => {
      return !!matchPath(pathname, { path });
    },
    [pathname]
  );

  /**
   * Mutations
   */

  const switchingCompany = useMutation({
    mutationFn: (params: SwitchCompanyParams) => switchCompany(params),
    onSuccess: () => {
      window.location.assign(prDir || '/');
    },
  });

  /**
   * Dependent UIs
   */

  const footerUi = (
    <Flex column gap="16px">
      <Announcements />
      <FooterMenu isActive={isActive} openSupportModal={supportModalVisibility.setTrue} />
    </Flex>
  );

  /**
   * Render
   */

  return (
    <>
      <StyledSidebar footerUi={footerUi}>
        <Flex column gap="20px">
          {isSuperUser ? (
            <CompanyProfile company={company} />
          ) : (
            <CompanyPicker
              selectedMembership={company}
              selectableMemberships={otherMemberships}
              onSelect={switchingCompany.mutate}
              hasMultipleMemberships={memberships.length > 1}
            />
          )}

          <MainMenu isActive={isActive} />
        </Flex>
      </StyledSidebar>

      <SupportModal show={supportModalVisibility.value} onHide={supportModalVisibility.toggle} />
    </>
  );
};

export default LeftMenu;
