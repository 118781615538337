import { isEmpty, merge } from 'lodash';
import qs, { type IStringifyOptions } from 'qs';
import ApiError, { ResponseStatus } from 'utils/ApiError';
import config from 'utils/config';
import { destroyAuthCookies, getEmail } from 'utils/cookies';
import {
  deleteJSONOptions,
  getCsvOptions,
  getJSONOptions,
  getOptions,
  patchJSONOptions,
  postCSVOptions,
  postFormDataOptions,
  postJSONOptions,
  putJSONOptions,
  type RequestOptions,
} from './options';

export const RESPONSE_TIMEOUT = 120000;

export const API_VERSION = '2021-01-01';
export const API_ACCEPT_HEADER = `application/vnd.convictional.${API_VERSION}`;

let redirectingToLogin = false;
let redirectingToLocked = false;

const redirectToLogin = () => {
  // Do not automatically redirect if we are already on the login page or any of its children
  if (window.location.pathname.startsWith('/login')) return;
  if (window.location.pathname.startsWith('/signin')) return;

  if (!redirectingToLogin) {
    redirectingToLogin = true;
    // Client needs to reauthenticate
    const email = encodeURIComponent(getEmail() || '');
    destroyAuthCookies();
    window.location.replace(
      `/signin?reason=inactivity&email=${email}&redirect=${window.location.pathname}${window.location.search}`
    );
  }
};

const redirectToLocked = () => {
  if (window.location.pathname.startsWith('/locked')) return;
  if (!redirectingToLocked) {
    redirectingToLocked = true;
    window.location.replace(`/locked`);
  }
};

// utility that safely modifies the headers of a window.fetch init param to ensure
// the proper API version Accept header is always passed along
export const setRequestApiVersion = (options?: RequestOptions): RequestOptions => {
  let acceptHeaders = API_ACCEPT_HEADER;

  // Protect against empty strings
  if (!isEmpty(options?.headers?.Accept)) {
    acceptHeaders = `${acceptHeaders}, ${options?.headers?.Accept}`;
  }

  return merge({}, options, {
    headers: { Accept: acceptHeaders },
  });
};

export const request = async (url: string, options: RequestOptions, responseType = 'json') => {
  const init = setRequestApiVersion(options);

  // If the request does not have an abort controller attached, create a new one and attach it
  let timeout;
  if (!init.signal) {
    const controller = new AbortController();
    const { signal } = controller;
    init.signal = signal;

    // Set timeout for fetch request
    timeout = setTimeout(() => {
      controller.abort();
    }, RESPONSE_TIMEOUT);
  }

  try {
    const res = await window.fetch(url, init);
    clearTimeout(timeout);

    // either json or blob on success
    if (res?.ok) {
      // We can return early if the caller wants the full response
      // However this puts the onus on the caller to handle the response
      // and any errors that may occur
      if (options.shouldReturnFullResponse) return res;

      const body =
        responseType === 'json'
          ? await res.json().catch((error) => {
              if (!(error instanceof SyntaxError)) {
                // Workaround because not all of backend API endpoints return a JSON response
                throw error;
              }
            })
          : await res.blob();

      return body;
    }

    // on error status, try to parse JSON response even if blob was requested
    const body = await res.json().catch((error) => {
      if (!(error instanceof SyntaxError)) {
        // Workaround because not all of backend API endpoints return a JSON response
        throw error;
      } else if (res.status === 404) {
        // 404s will throw a syntax error (JSON is not returned)
        throw new ApiError('Not Found', 404);
      }
    });

    // We're either dealing with old or new style errors
    const errorBody = body.error || body.errors;
    throw new ApiError(errorBody, res.status, body.code);
  } catch (error: any) {
    clearTimeout(timeout);

    // check if error has name
    if (error.name === 'AbortError' || error instanceof DOMException) {
      throw new ApiError('Request timed out', ResponseStatus.TIMEOUT);
    }
    if (error.name === 'TypeError' || error instanceof TypeError) {
      throw new ApiError('Network error');
    }

    throw error;
  }
};

export const refreshToken = () => {
  const url = `${config.apiURL}/login/refresh`;
  const options = postJSONOptions({});
  return request(url, options);
};

// Temporary fill in to catch requests that occur after session expires
// We will want to transition to preemptively checking if a user has live session
// but current session management restricts us to having to retry on failure
export const secureRequest = async (
  url: string,
  options: RequestOptions,
  responseType: string = 'json'
) => {
  try {
    return await request(url, options, responseType);
  } catch (error) {
    if (!(error instanceof ApiError)) throw error;

    if (error.isLocked) {
      redirectToLocked();
    }
    // first request failed, check if they are missing required cookies
    if (error.isUnauthorized && error.isCookieInvalid) {
      redirectToLogin();
      throw error;
    }

    // if error is not related to auth refresh, throw it and exit early
    if (!(error.isUnauthorized && error.shouldRefresh)) throw error;

    // refresh required
    const possibleError = await refreshToken().catch((e) => e);
    if (possibleError instanceof ApiError) {
      // If the refresh did not succeed, and it is currently not locked in another process
      // log the user out
      if (possibleError.shouldReauthenticate) {
        redirectToLogin();
        throw possibleError;
      }
    }

    // try again after refresh
    return request(url, options, responseType);
  }
};

export const getRequest = (
  endpoint: string,
  params?: Record<string, any>,
  headers?: Record<string, string>,
  options?: RequestOptions,
  arrayFormat: IStringifyOptions['arrayFormat'] = 'comma'
) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${qs.stringify(params, { arrayFormat })}`;
  }
  const url = config.apiURL + endpoint + queryStr;
  return secureRequest(url, getJSONOptions(headers, options));
};

export const getBlobRequest = (
  endpoint: string,
  params?: Record<string, any>,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${qs.stringify(params)}`;
  }
  const url = config.apiURL + endpoint + queryStr;
  return secureRequest(url, getOptions(headers, options), 'blob');
};

export const patchRequest = (
  endpoint: string,
  data?: any,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  const url = config.apiURL + endpoint;
  return secureRequest(url, patchJSONOptions(data, headers, options));
};

export const postRequest = (
  endpoint: string,
  data?: any,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  const url = config.apiURL + endpoint;

  if (data instanceof FormData) {
    return secureRequest(url, postFormDataOptions(data, headers, options));
  }

  return secureRequest(url, postJSONOptions(data, headers, options));
};

export const putRequest = (
  endpoint: string,
  data?: any,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  const url = config.apiURL + endpoint;
  return secureRequest(url, putJSONOptions(data, headers, options));
};

export const postCSVRequest = (
  endpoint: string,
  data?: any,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  const url = config.apiURL + endpoint;
  return secureRequest(url, postCSVOptions(data, headers, options));
};

export const getCsvRequest = (
  endpoint: string,
  params?: Record<string, any>,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  let queryStr = '';
  if (params && Object.keys(params).length > 0) {
    queryStr = `?${qs.stringify(params)}`;
  }
  const url = config.apiURL + endpoint + queryStr;
  return secureRequest(url, getCsvOptions(headers, options), 'application/zip');
};

export const deleteRequest = (
  endpoint: string,
  data?: any,
  headers?: Record<string, string>,
  options?: RequestOptions
) => {
  const url = config.apiURL + endpoint;
  return secureRequest(url, deleteJSONOptions(data, headers, options));
};
