import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import materialSymbol from 'storybookConfig/mixins/materialSymbol';
import typography from 'storybookConfig/mixins/typography';

export enum ButtonSizes {
  Large = 'large',
  Small = 'small',
}

export enum ButtonKinds {
  Action = 'action',
  Neutral = 'neutral',
  Destructive = 'destructive',
}

type GetButtonTypographyArg = Pick<React.ComponentProps<typeof Button>, 'disabled' | 'size'>;

export const getButtonTypography = ({
  disabled,
  size = ButtonSizes.Large,
}: GetButtonTypographyArg) => {
  if (size === ButtonSizes.Large && disabled) {
    return typography('Buttons/Button Disabled');
  }

  if (size === ButtonSizes.Large) {
    return typography('Buttons/Button');
  }

  if (size === ButtonSizes.Small && disabled) {
    return typography('Buttons/Button Small Disabled');
  }

  if (size === ButtonSizes.Small) {
    return typography('Buttons/Button Small');
  }

  return css``;
};

type GetButtonPaddingArg = Pick<
  React.ComponentProps<typeof Button>,
  '$iconName' | '$iconAfter' | 'size'
>;

export const getButtonPadding = ({
  $iconName,
  $iconAfter,
  size = ButtonSizes.Large,
}: GetButtonPaddingArg) => {
  const isIconOnLeft = $iconName && !$iconAfter;
  const isIconOnRight = $iconName && $iconAfter;

  if (size === ButtonSizes.Large && isIconOnLeft) {
    return css`
      padding-right: 32px;
      padding-left: 20px;
    `;
  }

  if (size === ButtonSizes.Large && isIconOnRight) {
    return css`
      padding-right: 20px;
      padding-left: 32px;
    `;
  }

  if (size === ButtonSizes.Large) {
    return css`
      padding-right: 32px;
      padding-left: 32px;
    `;
  }

  if (size === ButtonSizes.Small && isIconOnLeft) {
    return css`
      padding-right: 20px;
      padding-left: 12px;
    `;
  }

  if (size === ButtonSizes.Small && isIconOnRight) {
    return css`
      padding-right: 12px;
      padding-left: 20px;
    `;
  }

  if (size === ButtonSizes.Small) {
    return css`
      padding-right: 24px;
      padding-left: 24px;
    `;
  }

  return css``;
};

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * A name of an icon to display before the button text, by default
   */
  $iconName?: string;
  /**
   * Display the icon after the button text
   */
  $iconAfter?: boolean;
  /**
   * The size of the button
   */
  size?: `${ButtonSizes}`;
  /**
   * The HTML element to render the button as
   */
  as?: React.ElementType;
}

/**
 * This is a basic button offering. It's goal is to provide a consistent, mostly unstyled
 * button component that can be used in a variety of contexts. It is composed by the
 * `PrimaryButton`, `SecondaryButton`, and `TertiaryButton` components.
 */

const ButtonBase = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ type = 'button', ...rest }: ButtonProps, ref) => {
    // eslint-disable-next-line react/button-has-type
    return <button ref={ref} type={type} {...rest} />;
  }
);

const Button = styled(ButtonBase, {
  shouldForwardProp: (prop) => !['as', '$iconName', '$iconAfter', 'size'].includes(prop),
})<ButtonProps>`
  all: unset;
  display: inline-flex;
  gap: 8px;
  justify-content: space-around;
  align-items: center;

  &:hover {
    cursor: pointer;
    color: inherit;
    text-decoration: none !important; // Important when casting a 'Link' as a Button-type component
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ size = ButtonSizes.Large }) => {
    switch (size) {
      case ButtonSizes.Large:
        return css`
          height: 44px;
        `;
      case ButtonSizes.Small:
        return css`
          height: 32px;
        `;
      default:
        return css``;
    }
  }}

  ${({ $iconName, $iconAfter }) =>
    $iconName &&
    materialSymbol({
      name: $iconName,
      position: $iconAfter ? 'after' : 'before',
      size: '24px',
      grade: 100,
    })}
`;

export default Button;
