import Flex from '@react-css/flex';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import useStagingBuild from 'hooks/useStagingBuild';
import qs from 'qs';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Redirect, useHistory, useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import Card from 'storybookConfig/stories/cells/Card';
import Body from 'storybookConfig/stories/molecules/Body';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import Input from 'storybookConfig/stories/molecules/Input';
import Link from 'storybookConfig/stories/molecules/Link';
import SupportLink from 'storybookConfig/stories/molecules/Link/SupportLink';
import Form from 'storybookConfig/stories/organisms/Form';
import Layout from 'storybookConfig/stories/species/Layout';
import type ApiError from 'utils/ApiError';
import { generateOTP, type GenerateOtpParams } from 'utils/api/login';

const useInactivityAlert = () => {
  const location = useLocation();
  const { addErrorAlert } = useAlertQueue();

  useEffect(() => {
    const { reason } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (reason === 'inactivity') {
      addErrorAlert('Please log in again', 'You have been logged out due to inactivity');
    }
  }, [location.search, addErrorAlert]);
};

const SignInPage = () => {
  const user = useAppSelector(selectUser);
  const location = useLocation();
  const history = useHistory();
  const alertQueue = useAlertQueue();
  const prDir = useStagingBuild();

  useInactivityAlert();

  /**
   * Helpers
   */

  const { email } = qs.parse(location.search, { ignoreQueryPrefix: true });

  /**
   * Form
   */

  interface FormFields {
    email: string;
  }

  const { handleSubmit, register, formState, resetField } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      email: (email || '') as string,
    },
  });

  /**
   * Mutations
   */

  const generatingOTP = useMutation({
    mutationFn: (params: GenerateOtpParams) =>
      generateOTP(prDir ? { ...params, rootDir: prDir } : params),
    onSuccess: (data, variables) => {
      if (data?.link) return window.location.assign(data.link);
      return history.push('/signin/success', { email: variables.email, backPath: '/signin' });
    },
    onError: (error: ApiError) => {
      if (error.isNotFound) {
        alertQueue.addErrorAlert(
          "This account couldn't be found",
          'Did you mean to use a different email address?'
        );
        // Clear the bad email address
        resetField('email');
      } else {
        alertQueue.addErrorAlert('Something went wrong', 'Please contact support@convictional.com');
        Sentry.captureException(error);
      }
    },
  });

  /**
   * Render
   */

  if (user) return <Redirect to="/" />;

  return (
    <Layout.Root>
      <Layout.Content>
        <Flex column gap="32px" alignItemsCenter>
          <Layout.Logo />

          <Card padding="48px">
            <Card.Body>
              <Flex column gap="32px">
                <Heading variant="Headings/H2">Sign in to your Modern Dropship account</Heading>

                <Form onSubmit={handleSubmit((data) => generatingOTP.mutate(data))}>
                  <Form.FieldGroup>
                    <Form.Field
                      labelText="Email"
                      id="email"
                      errorText={formState.errors.email?.message}
                      isRequired
                    >
                      <Input
                        type="email"
                        {...register('email', { required: 'Email is required' })}
                        isFullWidth
                        placeholder="email@example.com"
                      />
                    </Form.Field>
                  </Form.FieldGroup>

                  <Form.Footer column gap="16px">
                    <PrimaryButton type="submit" disabled={generatingOTP.isLoading}>
                      Sign In
                    </PrimaryButton>
                  </Form.Footer>

                  <Flex.Item alignSelfCenter>
                    <Body>
                      <SupportLink article="dxwkuz7iz1">Sign In Help?</SupportLink> • Don&#39;t have
                      an account? <Link to="/signup">Sign up!</Link>
                    </Body>
                  </Flex.Item>
                </Form>
              </Flex>
            </Card.Body>
          </Card>
        </Flex>
      </Layout.Content>
    </Layout.Root>
  );
};

export default SignInPage;
