import styled from '@emotion/styled';
/**
 * A styled button component used to appear as a link
 * use this instead of <Link as={Button} /> to avoid a11y lint errors
 */
const LinkButton = styled.button`
  all: unset;
  cursor: pointer;
  color: ${({ theme }) => theme.color.bodyTextLinks};
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.color.bodyTextLinksHover};
    text-decoration: underline;
  }
`;

export default LinkButton;
