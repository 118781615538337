import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ThemeColor } from 'storybookConfig/stories/theme';

interface SpinnerProps {
  /**
   * Width & Height of the spinner
   */
  size?: string;
  /**
   * Color of the spinner
   */
  color?: ThemeColor;
  /**
   * For styling
   */
  className?: string;
}

const Wrapper = styled.div<SpinnerProps>`
  display: inline-block;
  position: relative;

  ${({ size = '80px' }) => css`
    width: ${size};
    height: ${size};
  `}

  span {
    box-sizing: border-box;
    display: block;
    position: absolute;
    border-radius: 50%;
    animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    ${({ theme, size = '80px', color = 'blue500' }) => css`
      width: calc(${size} * 0.8);
      height: calc(${size} * 0.8);
      margin: calc(${size} * 0.1);
      border: calc(${size} * 0.1) solid ${theme.color[color]};
      border-color: ${theme.color[color]} transparent transparent transparent;
    `};
  }

  span:nth-of-type(1) {
    animation-delay: -0.45s;
  }

  span:nth-of-type(2) {
    animation-delay: -0.3s;
  }

  span:nth-of-type(3) {
    animation-delay: -0.15s;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

/**
 * A spinning loading indicator
 */

const Spinner = (props: SpinnerProps) => (
  <Wrapper aria-label="Loading" {...props}>
    <span />
    <span />
    <span />
    <span />
  </Wrapper>
);

export default styled(Spinner)``;
