import { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

// Lazy loading the larger components to improve performance

import LoginSuccessPage from 'containers/LoginSuccessPage';
import LogoutPage from 'containers/LogoutPage';
import OnboardingPage from 'containers/OnboardingPage';
import SignInPage from 'containers/SignInPage';
import SignInSuccessPage from 'containers/SignInSuccessPage';
import SignUpPage from 'containers/SignUpPage';

const AgreementPage = lazy(() => import('containers/Agreements/AgreementPage'));
const DownloadPage = lazy(() => import('containers/Download/DownloadPage'));
const EDISchemaPage = lazy(() => import('containers/EDISchemas/EDISchemaPage'));
const EDISchemasPage = lazy(() => import('containers/EDISchemas/EDISchemasPage'));
const GetStartedPage = lazy(() => import('containers/GetStarted/GetStartedPage'));
const HomePage = lazy(() => import('containers/HomePage'));
const InvoicePage = lazy(() => import('containers/Invoices/InvoicePage'));
const InvoicesPage = lazy(() => import('containers/Invoices/InvoicesPage'));
const LockedPage = lazy(() => import('containers/LockedPage'));
const OrderPage = lazy(() => import('containers/OrderPage'));
const OrderPackingSlipPage = lazy(() => import('containers/OrderPage/OrderPackingSlipPage'));
const OTAOrderPackingSlipPage = lazy(() => import('containers/OrderPage/OTAOrderPackingSlipPage'));
const OrdersPage = lazy(() => import('containers/OrdersPage'));
const PartnerPage = lazy(() => import('containers/PartnerPage'));
const PartnersPage = lazy(() => import('containers/PartnersPage'));
const PriceListPage = lazy(() => import('containers/PriceListPage'));
const PricesPage = lazy(() => import('containers/PriceListsPage'));
const CreatePriceList = lazy(() => import('containers/PriceListsPage/CreatePriceList'));
const ProductPage = lazy(() => import('containers/Product/ProductPage'));
const ProductsPage = lazy(() => import('containers/Products'));
const ReportingPage = lazy(() => import('containers/ReportingPage'));
const SettingsPage = lazy(() => import('containers/SettingsPage'));
const TradeOpsIssuesPage = lazy(() => import('containers/TradeOpsIssues/TradeOpsIssuesPage'));
const NotFoundPage = lazy(() => import('./NotFoundPage'));

const AppRouter = () => {
  return (
    <Switch>
      {/* Home */}
      <PrivateRoute exact path="/" component={HomePage} />

      {/* Auth */}
      <Redirect exact from="/login" to="/signin" />
      <PublicRoute exact path="/signup" component={SignUpPage} />
      <PublicRoute exact path="/signin" component={SignInPage} />
      <PublicRoute exact path="/signin/success" component={SignInSuccessPage} />
      <PublicRoute exact path="/login/:token" component={LoginSuccessPage} />
      <PublicRoute exact path="/logout" component={LogoutPage} />

      <PrivateRoute exact path="/locked" component={LockedPage} />

      {/* Onboarding */}
      <PrivateRoute exact path="/onboarding" component={OnboardingPage} showNav={false} />
      <PrivateRoute exact path="/get-started" component={GetStartedPage} />

      {/* Orders */}
      <PrivateRoute exact path="/orders" component={OrdersPage} />
      <PrivateRoute exact path="/orders/:orderId" component={OrderPage} />
      <PrivateRoute
        exact
        path="/orders/:orderId/packingslip"
        component={OrderPackingSlipPage}
        showNav={false}
      />
      <PublicRoute
        exact
        path="/ota/orders/:orderId/packingslip"
        component={OTAOrderPackingSlipPage}
      />

      {/* Partners */}
      <PrivateRoute exact path="/partners" component={PartnersPage} />
      <PrivateRoute exact path="/partners/:partnerId" component={PartnerPage} />

      {/* Products */}
      <PrivateRoute exact path="/products" component={ProductsPage} />
      <PrivateRoute exact path="/products/:productId" component={ProductPage} />

      {/* Settings */}
      <PrivateRoute exact path="/settings/:tab?/:section?" component={SettingsPage} />

      {/* TradeOps */}
      <PrivateRoute exact path="/issues" component={TradeOpsIssuesPage} />

      {/* Pricing */}
      <PrivateRoute exact path="/prices" component={PricesPage} />
      <PrivateRoute exact path="/prices/create" component={CreatePriceList} />
      <PrivateRoute exact path="/prices/:priceId" component={PriceListPage} />

      {/* Invoices */}
      <PrivateRoute exact path="/invoices" component={InvoicesPage} />
      <PrivateRoute exact path="/invoices/:id" component={InvoicePage} />

      {/* Agreements */}
      <PrivateRoute exact path="/agreements/:agreementId" component={AgreementPage} />

      {/* EDI */}
      <PrivateRoute exact path="/edi/documents" component={EDISchemasPage} showNav={false} />
      <PrivateRoute
        exact
        path="/edi/documents/:documentNumber"
        component={EDISchemaPage}
        showNav={false}
      />

      {/* Download */}
      <PrivateRoute exact path="/download/:documentId" component={DownloadPage} showNav={false} />

      {/* Reporting */}
      <PrivateRoute exact path="/reporting" component={ReportingPage} />

      {/* 404 */}
      <PublicRoute component={NotFoundPage} />
    </Switch>
  );
};

export default AppRouter;
