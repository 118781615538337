import styled from '@emotion/styled';
import Grid from '@react-css/grid';
import typography from 'storybookConfig/mixins/typography';
import LinkButton from 'storybookConfig/stories/molecules/Link/LinkButton';
import { AiIconWrapper, MessageContent } from 'storybookConfig/stories/species/Chat/Shared.styles';

const StyledMessageContent = styled(MessageContent)`
  background-color: ${({ theme }) => theme.color.gray100};
  ${typography('Body/Regular Italics')};
  color: ${({ theme }) => theme.color.bodyTextSecondary};
`;

interface ChatAwaitingAiMessageProps {
  onInterruptButtonClick: () => void;
}

const ChatAwaitingAiMessage = ({ onInterruptButtonClick }: ChatAwaitingAiMessageProps) => {
  return (
    <Grid columns="40px 1fr" gap="8px" alignItemsStart>
      <AiIconWrapper isLoading />
      <Grid.Item>
        <StyledMessageContent>
          Working on it... <LinkButton onClick={onInterruptButtonClick}>Interrupt?</LinkButton>
        </StyledMessageContent>
      </Grid.Item>
    </Grid>
  );
};

export default ChatAwaitingAiMessage;
