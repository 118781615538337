import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import FloatingChatUI from 'components/Dashboard/FloatingChatUI';
import AddStoreUrl from 'containers/OnboardingPage/AddStoreUrl';
import CommerceTypeSelection from 'containers/OnboardingPage/CommerceTypeSelection';
import CompanyFields from 'containers/OnboardingPage/CompanyFields';
import PlatformSelection from 'containers/OnboardingPage/PlatformSelection';
import Review from 'containers/OnboardingPage/Review';
import useAlertQueue from 'hooks/useAlertQueue';
import useOnboardingStorage from 'hooks/useOnboardingStorage';
import useScopedSentryMessage from 'hooks/useScopedSentryMessage';
import useUserOnboardingAttributes from 'hooks/useUserOnboardingAttributes';
import { FormProvider, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import { useAppDispatch } from 'store';
import { refreshMe } from 'store/slices/me';
import Card from 'storybookConfig/stories/cells/Card';
import Form from 'storybookConfig/stories/organisms/Form';
import Layout from 'storybookConfig/stories/species/Layout';
import { CommerceType, Platform } from 'types/models/company';
import { useCounter } from 'usehooks-ts';
import type ApiError from 'utils/ApiError';
import { createCompany, type CreateCompanyParams } from 'utils/api/companies';
import { switchCompany, type SwitchCompanyParams } from 'utils/api/login';
import { isPlatformModern } from 'utils/platform';
import { getDomainFromUrl } from 'utils/strings';

const OnboardingPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { onboardingStorage } = useOnboardingStorage();
  const stepCounter = useCounter(onboardingStorage?.currentStep ?? 1);
  const alertQueue = useAlertQueue();
  const sendSentryError = useScopedSentryMessage('error');
  const userOnboardingAttributes = useUserOnboardingAttributes();
  const location = useLocation<{ isCreatingAnotherCompany?: boolean }>();

  const {
    source = '',
    storeUrl = '',
    companyUrl = '',
    companyName = '',
    temporaryTokenId = '',
    platform = onboardingStorage?.platform ?? Platform.Shopify,
    commerceType = onboardingStorage?.commerceType ?? CommerceType.Seller,
  } = userOnboardingAttributes;

  /**
   * Form
   */

  interface FormValues {
    storeUrl?: string;
    platform: Platform;
    companyUrl: string;
    companyName: string;
    commerceType: CommerceType;
  }

  const form = useForm<FormValues>({
    mode: 'onChange',
    values: {
      companyName,
      companyUrl,
      commerceType,
      platform,
      storeUrl: getDomainFromUrl(storeUrl),
    },
  });

  /**
   * Helpers
   */

  const selectedPlatform = form.watch('platform');
  const requiresStoreUrl = isPlatformModern(selectedPlatform);
  const totalSteps = isPlatformModern(selectedPlatform) ? 5 : 4;
  const isCompanyStep = stepCounter.count === totalSteps - 1;
  const isLastStep = stepCounter.count === totalSteps;
  const isShopifyInstall = source === 'shopify' && storeUrl !== '';
  const isCreatingAnotherCompany = !!location.state?.isCreatingAnotherCompany;

  /**
   * Mutations
   */

  const switchingCompany = useMutation({
    mutationFn: (params: SwitchCompanyParams) => switchCompany(params),
    onError: (error: ApiError) => {
      alertQueue.addErrorAlert('Unable to switch companies', error.message);

      sendSentryError('Failed to switch companies', {
        extra: {
          error,
          userOnboardingAttributes,
        },
      });
    },
  });

  interface CreateCompanyMutationArgs {
    creatingCompanyParams: CreateCompanyParams;
  }

  const creatingCompany = useMutation({
    mutationFn: ({ creatingCompanyParams }: CreateCompanyMutationArgs) =>
      createCompany(creatingCompanyParams),
    onSuccess: async ({ companyObjectId }) => {
      alertQueue.addSuccessAlert('Success', 'Your new company has been created!');

      if (isCreatingAnotherCompany) {
        await switchingCompany.mutateAsync({ companyObjectId });
      }

      await dispatch(refreshMe());

      history.push('/orders');
    },
    onError: (error: ApiError) => {
      if (error.isConflict) {
        alertQueue.addErrorAlert(
          'Company already exists',
          "Are you sure you don't want to sign-in instead? You may need to use a different email address"
        );
      } else {
        alertQueue.addErrorAlert('Unable to create company', error.message);

        sendSentryError('Failed to create company', {
          extra: {
            error,
            userOnboardingAttributes,
          },
        });
      }
    },
  });

  /**
   * Handlers
   */

  const onSubmit = form.handleSubmit((values) => {
    const creatingCompanyParams: CreateCompanyParams = {
      company: {
        source,
        name: values.companyName,
        companyUrl: getDomainFromUrl(values.companyUrl),
        temporaryTokenId,
        platform: values.platform,
        commerceType: values.commerceType,
      },
    };

    if (values.storeUrl) {
      creatingCompanyParams.company.storeUrl = `https://${getDomainFromUrl(values.storeUrl)}`;
    }

    return creatingCompany.mutateAsync({ creatingCompanyParams });
  });

  /**
   * Render
   */

  return (
    <Layout.Root>
      <Layout.Content $width="720px">
        <Flex column gap="32px" alignItemsCenter>
          <Layout.Logo />

          <Card padding="48px 80px" $isFullWidth>
            <FormProvider {...form}>
              <Form onSubmit={onSubmit}>
                {stepCounter.count === 1 && (
                  <CommerceTypeSelection
                    currentStep={stepCounter.count}
                    totalSteps={totalSteps}
                    onNextButtonClick={stepCounter.increment}
                  />
                )}

                {stepCounter.count === 2 && (
                  <PlatformSelection
                    currentStep={stepCounter.count}
                    totalSteps={totalSteps}
                    onNextButtonClick={stepCounter.increment}
                    onBackButtonClick={stepCounter.decrement}
                  />
                )}

                {stepCounter.count === 3 && requiresStoreUrl && (
                  <AddStoreUrl
                    currentStep={stepCounter.count}
                    totalSteps={totalSteps}
                    onNextButtonClick={stepCounter.increment}
                    onBackButtonClick={stepCounter.decrement}
                    isShopifyInstall={isShopifyInstall}
                  />
                )}

                {isCompanyStep && (
                  <CompanyFields
                    currentStep={stepCounter.count}
                    totalSteps={totalSteps}
                    onNextButtonClick={stepCounter.increment}
                    onBackButtonClick={stepCounter.decrement}
                    isShopifyInstall={isShopifyInstall}
                  />
                )}

                {isLastStep && (
                  <Review
                    currentStep={stepCounter.count}
                    totalSteps={totalSteps}
                    onBackButtonClick={() => stepCounter.setCount(1)}
                    isShopifyInstall={isShopifyInstall}
                  />
                )}
              </Form>
            </FormProvider>
          </Card>
        </Flex>
      </Layout.Content>

      <FloatingChatUI />
    </Layout.Root>
  );
};

export default OnboardingPage;
