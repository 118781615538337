import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { DateSelectOption } from 'hooks/useDateSelectOptions';
import type { RefCallBack } from 'react-hook-form';
import type { GroupBase, Props } from 'react-select';
import ReactSelect from 'react-select';
import CreatableReactSelect from 'react-select/creatable';
import elevate from 'storybookConfig/mixins/elevate';
import materialSymbol from 'storybookConfig/mixins/materialSymbol';
import typography from 'storybookConfig/mixins/typography';
import type { InputProps } from 'storybookConfig/stories/molecules/Input';
import { INPUT_HEIGHT, InputVariants } from 'storybookConfig/stories/molecules/Input';
import type { ReactSelectOption } from 'types/general';

export const EMPTY_OPTION: ReactSelectOption = { value: '', label: 'Select...' };

type BaseStyledReactSelectProps = InputProps &
  Pick<React.ComponentProps<typeof Select>, 'isDisabled'>;

interface StyledReactSelectProps extends BaseStyledReactSelectProps {}

const Wrapper = styled.div<StyledReactSelectProps>`
  display: inline-flex;

  // Style reset
  .select__indicator,
  .select__value-container,
  .select__input-container,
  .select__menu-list {
    padding: 0;
    margin: 0;
  }

  .select__indicator svg,
  .select__indicator-separator {
    display: none;
  }

  .select__multi-value__remove {
    svg {
      display: none;
    }

    &:hover {
      background: transparent;
    }
  }

  // Basic styles
  .select {
    width: 250px;
    max-width: 100%;
    cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};

    ${({ isFullWidth }) =>
      isFullWidth &&
      css`
        width: 100%;
      `}
  }

  // Placeholder styles
  .select__placeholder {
    color: ${({ theme, isDisabled }) =>
      isDisabled ? theme.color.bodyTextDisabled : theme.color.bodyTextSecondary};
  }

  // Add the correct icon to the dropdown indicator
  .select__dropdown-indicator {
    ${materialSymbol({ name: 'arrow_drop_down', size: '28px' })};
  }

  // Add the correct icon to the clear indicator
  .select__clear-indicator {
    ${materialSymbol({ name: 'cancel' })};
  }

  // Styling multi-value options
  .select__multi-value {
    border-radius: 100px;
    ${typography('Chips/Chip Text Large')};
    color: ${({ theme }) => theme.color.bodyTextPrimary};
    border: 1px solid ${({ theme }) => theme.color.gray200};
    background: ${({ theme, variant = InputVariants.Gray }) =>
      variant === InputVariants.Gray ? theme.color.white : theme.color.blue050};
  }

  // Styling the multi-value remove icon
  .select__multi-value__remove {
    ${({ isDisabled }) =>
      materialSymbol({
        name: 'cancel',
        size: '14px',
        color: isDisabled ? 'gray300' : 'bodyTextLinks',
      })};

    &:hover {
      ${({ isDisabled }) =>
        !isDisabled &&
        materialSymbol({ name: 'cancel', size: '14px', color: 'bodyTextLinksHover' })};
    }
  }

  // Styling the dropdown
  .select__menu {
    ${({ isDisabled }) =>
      isDisabled &&
      css`
        display: none;
      `}

    ${elevate('2')};
    border-radius: 16px;
    padding: 16px;

    .select__menu-list {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .select__option {
      ${typography('Inputs/Input')};
      color: ${({ theme }) => theme.color.bodyTextPrimary};
      padding: 8px 12px;
      border-radius: 4px;
    }

    .select__option--is-focused {
      background: ${({ theme }) => theme.color.gray100};
    }

    .select__option--is-selected {
      background: ${({ theme }) => theme.color.blue100};
    }
  }

  // This is the main input-looking thing
  .select__control {
    width: 100%;
    min-height: ${INPUT_HEIGHT}px;
    align-items: center;
    border-radius: ${INPUT_HEIGHT / 2}px;
    padding: 0 16px;
    border: 1px solid transparent;
    box-shadow: none;
    cursor: inherit;

    ${({ theme, variant = InputVariants.Gray }) => {
      switch (variant) {
        case InputVariants.Gray:
          return css`
            background: ${theme.color.gray100};
          `;
        case InputVariants.White:
          return css`
            background: ${theme.color.white};
          `;
        default:
          return css``;
      }
    }}

    ${({ theme, isDisabled }) =>
      isDisabled
        ? css`
            color: ${theme.color.bodyTextDisabled};
            ${typography('Inputs/Input Disabled')};
          `
        : css`
            color: ${theme.color.bodyTextPrimary};
            ${typography('Inputs/Input')};
          `};

    // When the select is focused or it's menu is open
    &.select__control--menu-is-open,
    &.select__control--is-focused {
      border-color: ${({ theme }) => theme.color.gray300};
    }

    // When a value is selected
    &:has(.select__value-container--has-value) {
      border-color: ${({ theme }) => theme.color.blue400};
    }
  }
`;

export interface SelectProps extends Omit<InputProps, 'defaultValue' | 'value' | 'onChange'> {
  /**
   * Switches between Creatable and Regular selects
   */
  isCreatable?: boolean;
  /**
   * Makes the width 100% of the parent container
   */
  isFullWidth?: boolean;
  /**
   * Passes a ref to the underlying react-select instance
   */
  innerRef?: RefCallBack;
  defaultValue?: ReactSelectOption<string>[] | ReactSelectOption<string> | DateSelectOption;
}

/**
 * This component is a wrapper around a react-select instance, providing styling.
 * Refer to https://react-select.com/ for usage.
 *
 * One note: when using this component inside a React Select Controller, spreading the
 * `field` argument, like the docs indicate, will cause a `ref` error. Due to the wild
 * way React Select type-sniffs its props, it's not possible to pass the ref through
 * forwardRef. You can, however, pass the ref through the `innerRef` prop.
 *
 * An example would look something like this:
 *
 * ```tsx
 * <Controller
 *   name="some-name"
 *   control={control}
 *   render={({ field: { ref, ...restFieldProps } }) => (
 *     <Select
 *       innerRef={ref}
 *       {...restFieldProps}
 *       options={OPTIONS}
 *     />
 *   )}
 * />
 * ```
 */
const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  variant,
  isDisabled,
  isCreatable,
  isFullWidth,
  className,
  innerRef,
  defaultValue,
  ...rest
}: SelectProps & Props<Option, IsMulti, Group>) => (
  <Wrapper
    isDisabled={isDisabled}
    variant={variant}
    className={className}
    isFullWidth={isFullWidth}
  >
    {isCreatable ? (
      <CreatableReactSelect
        {...rest}
        className="select"
        defaultValue={defaultValue}
        classNamePrefix="select"
        ref={innerRef}
      />
    ) : (
      <ReactSelect
        {...rest}
        className="select"
        defaultValue={defaultValue}
        classNamePrefix="select"
        ref={innerRef}
      />
    )}
  </Wrapper>
);

export default Select;
