import styled from '@emotion/styled';
import type { FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import typography from 'storybookConfig/mixins/typography';

/**
 * Props
 */
export type Size = 'sm' | 'md' | 'lg' | 'xl';

interface ModalProps {
  children: ReactNode;
  animation?: boolean; // default true
  centered?: boolean; // vertically center the modal; default false
  onHide?: () => void;
  onShow?: () => void;
  show?: boolean; // controlled show/hide
  size?: Size; // default 'md'
  // Additional props used for testing and accessibility
  'aria-labelledby'?: string;
  'data-testid'?: string;
  id?: string;
}

interface HeaderProps {
  children: ReactNode;
  closeButton?: boolean; // default false
}

/**
 * Context
 */
type ContextType = {
  handleClose: () => void;
};
const ModalContext = createContext<ContextType | null>(null);

const useModalContext = () => {
  const context = useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal.Root />');
  }

  return context;
};

/**
 * Components
 */
const StyledBackdrop = styled.div<{ animation: boolean; isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  opacity: ${({ isOpen }) => (isOpen ? 0.6 : 0)};
  background: ${({ theme }) => theme.color.bodyTextSecondary};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transition: ${({ animation }) =>
    animation ? 'visibility 0.3s ease-in-out,opacity 0.3s ease-in-out' : 'none'};
`;

const StyledDialog = styled.div<{ animation: boolean; centered: boolean; isOpen: boolean }>`
  position: fixed;
  top: ${({ centered }) => (centered ? 0 : '32px')};
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: ${({ centered }) => (centered ? 'center' : 'flex-start')};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  z-index: 1041; // 1 higher than the Backdrop
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: ${({ animation }) =>
    animation ? 'visibility 0.3s ease-in-out,opacity 0.3s ease-in-out' : 'none'};
`;

const StyledModal = styled.div<{ size: Size }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 8px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 80vh;
  max-width: ${({ size }) => {
    switch (size) {
      case 'sm':
        return '400px';
      case 'lg':
        return '800px';
      case 'xl':
        return '1200px';
      default: {
        return '600px';
      }
    }
  }};
  background: ${({ theme }) => theme.color.white};
`;

const Root: FC<ModalProps> = ({
  animation = true,
  centered = false,
  onHide,
  onShow,
  show,
  size = 'md',
  children,
  ...rest // pass through any other props, like `data-testid` and `aria-labelledby`
}) => {
  const [uncontrolledShow, setUncontrolledShow] = useState<boolean>(true);
  const isOpen: boolean = useMemo(() => show ?? uncontrolledShow, [show, uncontrolledShow]);
  const handleClose = useCallback(() => {
    setUncontrolledShow(false);
    // Call onHide if it exists
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    onHide && onHide();
  }, [onHide]);
  const contextValue: ContextType = useMemo(() => ({ handleClose }), [handleClose]);

  // Call onShow when the modal is opened
  useEffect(() => {
    if (onShow && isOpen) {
      onShow();
    }
  }, [isOpen, onShow]);

  // Handle off-modal clicks
  const hovered = useRef(false);
  const onHoverStart = () => {
    hovered.current = true;
  };
  const onHoverEnd = () => {
    hovered.current = false;
  };
  const handleCloseOnOutsideClick = () => {
    if (!hovered.current) handleClose();
  };

  return (
    <ModalContext.Provider value={contextValue}>
      <StyledBackdrop animation={animation} isOpen={isOpen} />
      <StyledDialog
        animation={animation}
        centered={centered}
        isOpen={isOpen}
        onClick={handleCloseOnOutsideClick}
      >
        {isOpen && (
          <StyledModal
            size={size}
            onFocus={onHoverStart}
            onMouseEnter={onHoverStart}
            onMouseLeave={onHoverEnd}
            {...rest}
          >
            {children}
          </StyledModal>
        )}
      </StyledDialog>
    </ModalContext.Provider>
  );
};

/**
 * Header
 */
const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid ${({ theme }) => theme.color.gray200};
`;

const StyledCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.bodyTextSecondary};
`;

const Header: FC<HeaderProps> = ({ closeButton, children }) => {
  const { handleClose } = useModalContext();
  const CloseButton = (
    <StyledCloseButton onClick={handleClose} aria-label="Close">
      ×
    </StyledCloseButton>
  );
  return (
    <StyledHeader data-testid="modal-header">
      {children}
      {closeButton && CloseButton}
    </StyledHeader>
  );
};

/**
 * Title
 */
const StyledTitle = styled.div`
  ${typography('Headings/H3')};
  color: ${({ theme }) => theme.color.bodyTextPrimary};
  margin: 0;
`;

const Title: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledTitle data-testid="modal-title">{children}</StyledTitle>
);

/**
 * Body
 */
const StyledBody = styled.div`
  padding: 24px;
`;

const Body: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledBody data-testid="modal-body">{children}</StyledBody>
);

/**
 * Footer
 */
const StyledFooter = styled.div`
  display: flex;
  padding: 0 24px 24px 24px;
  justify-content: flex-end;
  gap: 16px;
`;

const Footer: FC<{ children: ReactNode }> = ({ children }) => (
  <StyledFooter data-testid="modal-footer">{children}</StyledFooter>
);

export default {
  Root,
  Header,
  Title,
  Body,
  Footer,
};
