import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';
import type { ButtonProps } from 'storybookConfig/stories/molecules/Button';
import Button, {
  ButtonKinds,
  ButtonSizes,
  getButtonPadding,
  getButtonTypography,
} from 'storybookConfig/stories/molecules/Button';
import type { PickEnum } from 'types/general';
import type { LinkProps } from 'react-router-dom';

export type PrimaryButtonKinds = PickEnum<
  ButtonKinds,
  ButtonKinds.Action | ButtonKinds.Destructive
>;

type GetButtonColorSchemeArg = Pick<PrimaryButtonProps, '$iconAfter' | 'kind'>;

const getButtonColorScheme = ({
  $iconAfter,
  kind = ButtonKinds.Action,
  theme,
}: GetButtonColorSchemeArg & { theme: Theme }) => {
  if (kind === ButtonKinds.Action) {
    return css`
      background: ${theme.color.blue500};
      background-blend-mode: overlay, normal;
      color: ${theme.color.white} !important; // Overrides Link colors

      &:hover,
      &:focus {
        color: ${theme.color.white} !important; // Overrides Link colors
        background: ${theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.white};
      }
    `;
  }

  if (kind === ButtonKinds.Destructive) {
    return css`
      background: ${theme.color.error500};
      color: ${theme.color.white} !important; // Overrides Link colors

      &:hover,
      &:focus {
        background: ${theme.color.error700};
        color: ${theme.color.white} !important; // Overrides Link colors
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.white};
      }
    `;
  }

  return css``;
};

export interface PrimaryButtonProps extends ButtonProps {
  /**
   * The kind of action this button describes
   */
  kind?: `${PrimaryButtonKinds}`;

  /**
   * The HTML element to render the button as
   */
  as?: React.ElementType;

  /**
   * an optional route to navigate to if cast as a Link
   */
  to?: LinkProps['to'];

  /**
   * an optional target if cast as an a tag
   */
  target?: string;

  /**
   * an optional href if cast as an a tag
   */
  href?: string;
}

/**
 * A composed `Button` component that represents the primary action a user can take on a page.
 * - `size` is defaulted to `ButtonSizes.Large`
 * - `kind` is defaulted to `ButtonKinds.Action`
 */
const PrimaryButton = styled(Button, {
  shouldForwardProp: (prop) => !['kind', 'as', '$iconName'].includes(prop),
})<PrimaryButtonProps>`
  position: relative;
  border-radius: 100px;
  border: ${({ size = ButtonSizes.Large }) => (size === ButtonSizes.Large ? '2px' : '1px')} solid
    transparent;

  /* Set up inset ring for focus, using the pseudo selector not bound to an icon */
  ${({ $iconName, $iconAfter, size = ButtonSizes.Large }) => css`
    &::${$iconName && $iconAfter ? 'before' : 'after'} {
      position: absolute;
      inset: 3px;
      display: block;
      content: '';
      border: ${size === ButtonSizes.Large ? '2px' : '1px'} solid transparent;
      border-radius: 100px;
    }
  `}

  /* Determine appropriate typography */
  ${({ size = ButtonSizes.Large, disabled }) => getButtonTypography({ size, disabled })}

  /* Determine appropriate padding */
  ${({ size = ButtonSizes.Large, $iconName, $iconAfter }) => {
    return getButtonPadding({ size, $iconName, $iconAfter });
  }}

  /* Determine color scheme */
  ${({ kind, $iconAfter, theme }) => getButtonColorScheme({ kind, $iconAfter, theme })}

  /* Maintain a static disabled state */
  &:disabled {
    background: ${({ theme }) => theme.color.gray200};
    background-blend-mode: normal;
    color: ${({ theme }) => theme.color.bodyTextDisabled} !important; // Overrides Link colors
  }
`;

export default PrimaryButton;
