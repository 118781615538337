import * as Sentry from '@sentry/react';
import { useEffect } from 'react';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';
import config from 'utils/config';

if (config.sentry.dsn)
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 1.0,
  });

const useInitSentry = () => {
  const user = useAppSelector(selectUser);

  // If the user has loaded update Sentry with their info
  useEffect(() => {
    if (!user) return;

    if (user.flags?.stayAnonymous !== true) {
      Sentry.setUser({
        id: user._id,
        email: user?.emails?.[0]?.address,
      });
    }
  }, [user]);
};

export default useInitSentry;
