import Flex from '@react-css/flex';
import { useMutation } from '@tanstack/react-query';
import useAlertQueue from 'hooks/useAlertQueue';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import useStagingBuild from 'hooks/useStagingBuild';
import Card from 'storybookConfig/stories/cells/Card';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import Layout from 'storybookConfig/stories/species/Layout';
import type ApiError from 'utils/ApiError';
import type { VerifyOtpParams } from 'utils/api/login';
import { verifyOTP } from 'utils/api/login';
import { setEmail, setUserId } from 'utils/cookies';

const LoginSuccessPage = () => {
  const { token } = useParams<{ token: string }>();
  const { addErrorAlert } = useAlertQueue();
  const prDir = useStagingBuild();

  /**
   * Mutations
   */

  const verifyingOTP = useMutation({
    mutationFn: (params: VerifyOtpParams) => verifyOTP(params),
    onSuccess: (response) => {
      const userId = response?.user?._id;
      const email = response?.user?.emails[0]?.address ?? '';
      const hasOnboarded = response?.user?.memberships?.length > 0;

      setUserId(userId);
      setEmail(email);

      // If this is a staging build, redirect with the PR number as the root path
      if (prDir) return window.location.assign(prDir);

      // If onboarding isn't complete, assume fresh signup & make them onboard
      if (!hasOnboarded) return window.location.assign('/onboarding');

      // If onboarding is complete, and a redirect is present, redirect
      if (response?.redirect) return window.location.assign(response.redirect);

      // Otherwise they're likely a dropshipper, so redirect to orders
      return window.location.assign('/');
    },
    onError: (error: ApiError) => {
      addErrorAlert('Something went wrong', error.message);
    },
  });

  /**
   * Helpers
   */

  const isTokenValid = token.length < 5;
  const hasErrored = isTokenValid || verifyingOTP.isError;

  /**
   * Side effects
   */

  useEffect(() => {
    if (isTokenValid) {
      addErrorAlert(
        'Something went wrong',
        'Please try to login again and contact support@convictional.com if this issue persists.'
      );
    }
  }, [addErrorAlert, isTokenValid]);

  /**
   * Render
   */
  return (
    <Layout.Root>
      <Layout.Content>
        <Flex column gap="32px" alignItemsCenter>
          <Layout.Logo />

          <Card padding="48px">
            <Flex column gap="40px" data-testid="validate-login-page">
              {hasErrored ? (
                <PrimaryButton as={Link} to="/login" size="large">
                  Log In Again
                </PrimaryButton>
              ) : (
                <>
                  <Heading variant="Headings/H2" as="h1" align="center">
                    You are logged in!
                  </Heading>

                  <Flex column alignItemsStretch>
                    <PrimaryButton
                      size="large"
                      disabled={verifyingOTP.isLoading}
                      onClick={() => verifyingOTP.mutate({ token })}
                    >
                      Go to the App
                    </PrimaryButton>
                  </Flex>
                </>
              )}
            </Flex>
          </Card>
        </Flex>
      </Layout.Content>
    </Layout.Root>
  );
};

export default LoginSuccessPage;
