import { css } from '@emotion/react';
import styled from '@emotion/styled';
import BotImage from 'images/bot.png';
import typography from 'storybookConfig/mixins/typography';
import Spinner from 'storybookConfig/stories/molecules/Spinner';

export const MessageContent = styled.div`
  display: inline-block;
  ${typography('Body/Regular')};
  border-radius: 16px;
  padding: 8px 16px;

  p {
    margin: 0 0 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    white-space: pre-wrap;
  }
`;

interface AiIconWrapperProps {
  isLoading?: boolean;
  children?: React.ReactNode;
}

const AiIconBase = ({ isLoading, children }: AiIconWrapperProps) =>
  isLoading ? <Spinner size="24px" color="gray100" /> : children;

export const AiIconWrapper = styled(AiIconBase)<AiIconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100%;

  ${({ theme, isLoading }) =>
    isLoading
      ? css`
          background: ${theme.color.gray300};
        `
      : css`
          background: url(${BotImage}) no-repeat center / 100%;
        `};
`;
