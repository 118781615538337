import LeftMenu from './LeftMenu';

interface SideNavLayoutProps {
  children: React.ReactNode;
}

const SideNavLayout = ({ children }: SideNavLayoutProps) => {
  return (
    <>
      <LeftMenu />
      {children}
    </>
  );
};

export default SideNavLayout;
