import styled from '@emotion/styled';

import Input from 'storybookConfig/stories/molecules/Input';

const TextAreaInput = styled(Input, {
  shouldForwardProp: (prop) => !['as'].includes(prop),
})<{ as?: 'textarea' }>`
  border-radius: 16px;
  min-height: 135px;
  height: auto;
`;

export default TextAreaInput;
