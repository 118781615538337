import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import useAlertQueue from 'hooks/useAlertQueue';
import useScrollTo from 'hooks/useScrollTo';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import elevate from 'storybookConfig/mixins/elevate';
import Button from 'storybookConfig/stories/molecules/Button';
import SecondaryButton from 'storybookConfig/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybookConfig/stories/molecules/Button/TertiaryButton';
import Icon from 'storybookConfig/stories/molecules/Icon';
import TextAreaInput from 'storybookConfig/stories/molecules/Input/TextAreaInput';
import { ALERT_CLOSE_DURATION } from 'storybookConfig/stories/organisms/AlertQueue';
import {
  ConversationMessageFeedbackType,
  type ConversationMessageFeedback,
} from 'types/models/conversation';
import { useCopyToClipboard } from 'usehooks-ts';

interface IconWrapperProps {
  isActive?: boolean;
}

const Wrapper = styled.div`
  max-width: 80%;
`;

const IconWrapper = styled(Button, {
  shouldForwardProp: (prop) => !['isActive'].includes(prop),
})<IconWrapperProps>`
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.white};
  ${elevate('1')};

  ${Icon} {
    color: ${({ theme }) => theme.color.iconDefault};
  }

  ${({ isActive, theme }) =>
    isActive &&
    css`
      ${Icon} {
        color: ${theme.color.bodyTextPrimary};
      }
    `};

  ${({ disabled, theme }) =>
    !disabled &&
    css`
      &:hover {
        background-color: ${theme.color.blue500};

        ${Icon} {
          color: ${theme.color.white};
        }
      }
    `}
`;
interface FormFields {
  reason: string;
}

export interface ChatAiMessageActionsProps {
  content: string;
  messageId: string;
  onFeedbackFormSubmit: (messageId: string, feedback: ConversationMessageFeedback) => void;
}

const ChatAiMessageActions = ({
  content,
  messageId,
  onFeedbackFormSubmit,
}: ChatAiMessageActionsProps) => {
  const [activeButton, setActiveButton] = useState('');
  const copyTimeoutRef = useRef<number>();

  const [feedbackFormRef, scrollToFeedbackForm] = useScrollTo<HTMLFormElement>();
  const [copiedValue, copy] = useCopyToClipboard();
  const { addSuccessAlert } = useAlertQueue();

  const { handleSubmit, register, reset, formState } = useForm<FormFields>({
    mode: 'onChange',
    defaultValues: {
      reason: '',
    },
  });

  /**
   * Helpers
   */

  const shouldShowForm = ['thumb_up', 'thumb_down'].includes(activeButton);

  /**
   * Side Effects
   */

  // Add a little transition, activating the copy button, for the duration of the success message
  useEffect(() => {
    if (copiedValue && activeButton === 'content_copy') {
      addSuccessAlert('Success!', 'Message copied to clipboard.');

      copyTimeoutRef.current = window.setTimeout(() => {
        setActiveButton('');
      }, ALERT_CLOSE_DURATION);
    }

    return () => {
      if (copyTimeoutRef.current) clearTimeout(copyTimeoutRef.current);
    };
  }, [copiedValue, addSuccessAlert, activeButton]);

  // Each time the form should appear, scroll to the bottom of the messages
  useEffect(() => {
    if (shouldShowForm) scrollToFeedbackForm();
  }, [shouldShowForm, scrollToFeedbackForm]);

  /**
   * Event Handlers
   */

  const onCancelButtonClick = () => {
    setActiveButton('');
    reset();
  };

  const onSubmit = ({ reason }: FormFields) => {
    let type = ConversationMessageFeedbackType.NONE;

    if (activeButton === 'thumb_up') type = ConversationMessageFeedbackType.POSITIVE;
    if (activeButton === 'thumb_down') type = ConversationMessageFeedbackType.NEGATIVE;

    onFeedbackFormSubmit(messageId, { type, reason });
    setActiveButton('');
    reset();
  };

  /**
   * Render
   */

  return (
    <Wrapper>
      <Flex column gap="16px">
        <Flex gap="8px" justifyEnd>
          <IconWrapper
            onClick={() => setActiveButton('thumb_up')}
            isActive={activeButton === 'thumb_up'}
            aria-label="This response was positive"
          >
            <Icon name="thumb_up" />
          </IconWrapper>

          <IconWrapper
            onClick={() => setActiveButton('thumb_down')}
            isActive={activeButton === 'thumb_down'}
            aria-label="This response was negative"
          >
            <Icon name="thumb_down" />
          </IconWrapper>

          <IconWrapper
            onClick={() => {
              setActiveButton('content_copy');
              copy(content);
            }}
            isActive={activeButton === 'content_copy'}
            aria-label="Copy this response to the clipboard"
          >
            <Icon name="content_copy" />
          </IconWrapper>
        </Flex>

        {shouldShowForm && (
          <form onSubmit={handleSubmit(onSubmit)} ref={feedbackFormRef}>
            <Flex column gap="8px">
              <TextAreaInput
                {...register('reason')}
                as="textarea"
                placeholder="Please provide some additional feedback"
              />

              <Flex gap="8px" justifyEnd>
                <TertiaryButton type="reset" size="small" onClick={onCancelButtonClick}>
                  Cancel
                </TertiaryButton>

                <SecondaryButton type="submit" size="small" disabled={!formState.isValid}>
                  Submit
                </SecondaryButton>
              </Flex>
            </Flex>
          </form>
        )}
      </Flex>
    </Wrapper>
  );
};

export default ChatAiMessageActions;
