import styled from '@emotion/styled';
import ChatAiMessage from 'storybookConfig/stories/species/Chat/ChatAiMessage';
import ChatAiMessageActions from 'storybookConfig/stories/species/Chat/ChatAiMessageActions';
import ChatAwaitingAiMessage from 'storybookConfig/stories/species/Chat/ChatAwaitingAiMessage';
import ChatBody from 'storybookConfig/stories/species/Chat/ChatBody';
import ChatErrorMessage from 'storybookConfig/stories/species/Chat/ChatErrorMessage';
import ChatForm from 'storybookConfig/stories/species/Chat/ChatForm';
import ChatHeader from 'storybookConfig/stories/species/Chat/ChatHeader';
import ChatHumanMessage from 'storybookConfig/stories/species/Chat/ChatHumanMessage';
import ChatLoading from 'storybookConfig/stories/species/Chat/ChatLoading';
import ChatMessages from 'storybookConfig/stories/species/Chat/ChatMessages';
import ChatStatusMessage from 'storybookConfig/stories/species/Chat/ChatStatusMessage';

const Base = styled.div`
  max-height: 100%;
  background-color: ${({ theme }) => theme.color.white};
`;

/**
 * A complex compound component responsible for rendering the  chat window.
 */
const Chat = Object.assign(Base, {
  Header: ChatHeader,
  Body: ChatBody,
  Loading: ChatLoading,
  Messages: ChatMessages,
  AiMessage: ChatAiMessage,
  AiMessageActions: ChatAiMessageActions,
  AwaitingAiMessage: ChatAwaitingAiMessage,
  HumanMessage: ChatHumanMessage,
  StatusMessage: ChatStatusMessage,
  ErrorMessage: ChatErrorMessage,
  Form: ChatForm,
});

export default Chat;
