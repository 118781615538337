import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import typography from 'storybookConfig/mixins/typography';

export const INPUT_HEIGHT = 46;

export enum InputVariants {
  Gray = 'gray',
  White = 'white',
}

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  /**
   * The input's variant
   */
  variant?: `${InputVariants}`;
  /**
   * Should the input fill its container
   */
  isFullWidth?: boolean;
}

/**
 * A basic text input.
 */

const BaseInput = forwardRef<HTMLInputElement, InputProps>(
  ({ isFullWidth, type = 'text', ...props }, ref) => <input ref={ref} type={type} {...props} />
);

const Input = styled(BaseInput, {
  shouldForwardProp: (prop) => !['variant', 'isFullWidth'].includes(prop),
})<InputProps>`
  outline: 0;
  max-width: 100%;
  min-height: ${INPUT_HEIGHT}px;
  border-radius: ${INPUT_HEIGHT / 2}px;
  padding: 10px 16px;
  border: 1px solid transparent;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
      max-width: 100%;
    `}

  ${({ theme, variant = InputVariants.Gray }) => {
    switch (variant) {
      case InputVariants.Gray:
        return css`
          background: ${theme.color.gray100};
        `;
      case InputVariants.White:
        return css`
          background: ${theme.color.white};
        `;
      default:
        return css``;
    }
  }}

  ${({ theme, disabled, readOnly }) =>
    disabled || readOnly
      ? css`
          cursor: not-allowed;
          color: ${disabled ? theme.color.bodyTextDisabled : theme.color.bodyTextSecondary};
          ${typography('Inputs/Input Disabled')};
        `
      : css`
          color: ${theme.color.bodyTextPrimary};
          ${typography('Inputs/Input')};
        `};

  &::placeholder {
    color: ${({ theme, disabled }) =>
      disabled ? theme.color.bodyTextDisabled : theme.color.bodyTextSecondary};
  }

  // When the input is active or focused
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.color.gray300};
  }

  // When the input is not empty
  &:not(:placeholder-shown) {
    border-color: ${({ theme }) => theme.color.blue400};
  }

  // When the input is invalid
  &:invalid {
    border-color: ${({ theme }) => theme.color.error500};
  }

  // When disabled remove the border color
  &:disabled {
    border-color: transparent !important;
  }
`;

export default Input;
