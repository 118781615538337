import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import LogoSVG from 'images/logo.svg';
import typography from 'storybookConfig/mixins/typography';
import { ConversationMode } from 'types/models/conversation';
import { toProperCase } from 'utils/strings';

export const CHAT_HEADER_HEIGHT = 46;

const Wrapper = styled.div`
  padding: 12px 16px;
  background: ${({ theme }) => theme.color.gray900};
  height: ${CHAT_HEADER_HEIGHT}px;
`;

const Logo = styled.img`
  display: block;
  width: 20px;
  height: 20px;
`;

const Text = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${typography('Body/Body Small Bold')}
  color: ${({ theme }) => theme.color.white};
`;

const getHeaderText = (currentMode?: ConversationMode) => {
  if (currentMode) return toProperCase(currentMode);

  return 'Chat';
};

interface ChatHeaderProps {
  actionsUi?: React.ReactNode;
}

const ChatHeader = ({ actionsUi }: ChatHeaderProps) => {
  return (
    <Wrapper>
      <Flex alignItemsCenter gap="8px">
        <Logo alt="Modern Dropship logo" src={LogoSVG} />
        <Text>{getHeaderText(ConversationMode.Support)}</Text>
        {actionsUi}
      </Flex>
    </Wrapper>
  );
};

export default ChatHeader;
