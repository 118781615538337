import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';
import type { LinkProps } from 'react-router-dom';

import type { ButtonProps } from 'storybookConfig/stories/molecules/Button';
import Button, {
  ButtonKinds,
  ButtonSizes,
  getButtonPadding,
  getButtonTypography,
} from 'storybookConfig/stories/molecules/Button';

type GetButtonColorSchemeArg = Pick<SecondaryButtonProps, '$iconAfter' | 'kind'>;

const getButtonColorScheme = ({
  $iconAfter,
  kind = ButtonKinds.Action,
  theme,
}: GetButtonColorSchemeArg & { theme: Theme }) => {
  if (kind === ButtonKinds.Action) {
    return css`
      background: ${theme.color.white};
      color: ${theme.color.bodyTextLinks};
      border-color: ${theme.color.bodyTextLinks};

      &:hover,
      &:focus {
        background: ${theme.color.gray200};
        color: ${theme.color.gray700};
        border-color: ${theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.gray700};
      }
    `;
  }

  if (kind === ButtonKinds.Neutral) {
    return css`
      background: ${theme.color.white};
      color: ${theme.color.bodyTextSecondary};
      border-color: ${theme.color.bodyTextSecondary};

      &:hover,
      &:focus {
        background: ${theme.color.gray200};
        color: ${theme.color.gray700};
        border-color: ${theme.color.gray500};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.gray500};
      }
    `;
  }

  if (kind === ButtonKinds.Destructive) {
    return css`
      background: ${theme.color.white};
      color: ${theme.color.error500};
      border-color: ${theme.color.error500};

      &:hover,
      &:focus {
        background: ${theme.color.error100};
        color: ${theme.color.error700};
        border-color: ${theme.color.error700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.error700};
      }
    `;
  }

  return css``;
};

interface SecondaryButtonProps extends ButtonProps {
  /**
   * The kind of action this button describes
   */
  kind?: `${ButtonKinds}`;

  /**
   * The HTML element to render the button as
   */
  as?: React.ElementType;

  /**
   * an optional route to navigate to if cast as a Link
   */
  to?: LinkProps['to'];

  /**
   * an optional article route to navigate to if cast as a SupportLink
   */
  article?: string;

  /**
   * an optional target if cast as an a tag
   */
  target?: string;

  /**
   * an optional href if cast as an a tag
   */
  href?: string;
}

/**
 * A composed `Button` component that represents the secondary action a user can take on a page.
 * - `size` is defaulted to `ButtonSizes.Large`
 * - `kind` is defaulted to `ButtonKinds.Action`
 */
const SecondaryButton = styled(Button, {
  shouldForwardProp: (prop) => !['kind', 'as', '$iconName'].includes(prop),
})<SecondaryButtonProps>`
  position: relative;
  border-radius: 100px;
  border: ${({ size }) => (size === ButtonSizes.Large ? '2px' : '1px')} solid transparent;

  /* Set up inset ring for focus, using the pseudo selector not bound to an icon */
  ${({ $iconName, $iconAfter, size = ButtonSizes.Large }) => css`
    &::${$iconName && $iconAfter ? 'before' : 'after'} {
      position: absolute;
      inset: 3px;
      display: block;
      content: '';
      border: ${size === ButtonSizes.Large ? '2px' : '1px'} solid transparent;
      border-radius: 100px;
    }
  `}

  /* Determine appropriate typography */
  ${({ size = ButtonSizes.Large, disabled }) => getButtonTypography({ size, disabled })}

  /* Determine appropriate padding */
  ${({ size = ButtonSizes.Large, $iconName, $iconAfter }) =>
    getButtonPadding({ size, $iconName, $iconAfter })}

  /* Determine color scheme */
  ${({ kind, $iconAfter, theme }) => getButtonColorScheme({ kind, $iconAfter, theme })}

  /* Maintain a static disabled state */
  &:disabled {
    background: transparent;
    color: ${({ theme }) => theme.color.bodyTextDisabled};
    border-color: ${({ theme }) => theme.color.bodyTextDisabled};
  }
`;

export default SecondaryButton;
