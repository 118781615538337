import { css } from '@emotion/react';
import styled from '@emotion/styled';

import typography, { TypographyVariants } from 'storybookConfig/mixins/typography';
import type { ThemeColor } from 'storybookConfig/stories/theme';

type BodyVariants = Exclude<
  TypographyVariants,
  TypographyVariants.H1 | TypographyVariants.H2 | TypographyVariants.H4
>;

export interface BodyProps {
  /**
   * The heading variant to display. Passed directly to the `typography` helper.
   * Defaults to `Body/Regular`.
   */
  variant?: `${BodyVariants}`;
  /**
   * Horizontal alignment
   */
  align?: string;
  /**
   * Text color
   */
  color?: ThemeColor;
  /**
   * Truncate text
   */
  truncate?: boolean;
}

/**
 * Renders an inline piece of text styled to the given variant.
 * Omits Heading variants. Use the `Heading` component for those,
 * as that component also carries semantic meaning.
 *
 * To render a paragraph of text, use the the `as` prop:
 *
 * ```
 * <Body as="p" variant="Body/Regular">...</Body>
 * ```
 */
const Body = styled.span<BodyProps>`
  ${({ variant = TypographyVariants.BodyRegular }) => typography(variant)}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.color[color]};
    `}

  ${({ truncate }) =>
    truncate &&
    css`
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    `}

  margin: 0;
`;

export default Body;
