import styled from '@emotion/styled';
import WhiteLogoImage from 'images/Convicitonal-Horizontal-White.svg';
import BackgroundImageSVG from 'images/gradient-background.svg';
import type { ComponentProps } from 'react';

interface LayoutProps {
  hasBlueBackground?: boolean;
}

const Root = styled.div<LayoutProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px;
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: ${({ theme }) => theme.color.bodyBackground};

  ${({ hasBlueBackground = true }) =>
    hasBlueBackground && `background-image: url(${BackgroundImageSVG});`}
`;

interface ContentProps {
  $width?: string;
  $maxWidth?: string;
}

const Content = styled.div<ContentProps>`
  width: ${({ $width = 'auto' }) => $width};
  max-width: ${({ $maxWidth }) => $maxWidth};
`;

const Logo = styled((props: Omit<ComponentProps<'img'>, 'src' | 'alt'>) => (
  <img {...props} src={WhiteLogoImage} alt="Modern Dropship Logo" />
))`
  width: 205px;
`;

/**
 * Layout is a basic layout component that can be used to wrap content in a page.
 * It provides a consistent padding and background, and not much else.
 * It's meant to be composed by more specific layout components.
 */
const Layout = {
  Root,
  Content,
  Logo,
};

export default Layout;
