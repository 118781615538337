import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ElementType } from 'react';

import typography, { TypographyVariants } from 'storybookConfig/mixins/typography';
import type { ThemeColor } from 'storybookConfig/stories/theme';
import type { PickEnum } from 'types/general';

type HeadingVariant = `${PickEnum<
  TypographyVariants,
  TypographyVariants.H1 | TypographyVariants.H2 | TypographyVariants.H3 | TypographyVariants.H4
>}`;

export interface HeadingProps {
  /**
   * The heading variant to display. Passed directly to the `typography` helper.
   */
  variant?: HeadingVariant;
  /**
   * Horizontal alignment
   */
  align?: string;
  /**
   * Change the underlying HTML element, for a11y semantics
   */
  as?: ElementType;
  /**
   * Text color
   */
  color?: ThemeColor;

  children: React.ReactNode;

  /**
   * Optional class name to style
   */
  className?: string;
}

/**
 * Renders a heading. This component will try to glean the semantics of the supplied
 * `variant` and render a corresponding H1-H4 tag with proper styling. Override this
 * behavior as needed by using the `as` prop.
 */

const StyledHeading = styled('strong')<HeadingProps>`
  ${({ variant = TypographyVariants.H1 }) => typography(variant)}

  ${({ align }) =>
    align &&
    css`
      text-align: ${align};
    `}

  ${({ theme, color }) =>
    color &&
    css`
      color: ${theme.color[color]};
    `}

  margin: 0;
`;

const Heading = ({ children, as, variant = TypographyVariants.H1, ...props }: HeadingProps) => {
  let defaultAs: ElementType;
  switch (variant) {
    case TypographyVariants.H1:
      defaultAs = 'h1';
      break;
    case TypographyVariants.H2:
      defaultAs = 'h2';
      break;
    case TypographyVariants.H3:
      defaultAs = 'h3';
      break;
    case TypographyVariants.H4:
      defaultAs = 'h4';
      break;
    default:
      defaultAs = 'strong';
  }

  return (
    <StyledHeading as={as || defaultAs} variant={variant} {...props}>
      {children}
    </StyledHeading>
  );
};

export default Heading;
