import { css } from '@emotion/react';
import styled from '@emotion/styled';
import type { ComponentProps } from 'react';

import materialSymbol from 'storybookConfig/mixins/materialSymbol';
import resetButtonStyles from 'storybookConfig/mixins/resetButtonStyles';
import Input from 'storybookConfig/stories/molecules/Input';

const HEIGHT = 48;

export const SearchFormButton = styled((props: Omit<ComponentProps<'button'>, 'button'>) => (
  <button {...props} type="submit" />
))`
  ${resetButtonStyles};
  padding: 16px 8px 16px 24px;
  ${materialSymbol({ name: 'search', size: '16px' })}

  ${({ theme }) => css`
    background: ${theme.color.gray100};
  `}
`;

export const SearchFormInput = styled(Input)`
  padding: 16px 8px 16px;

  ${({ theme }) => css`
    background: ${theme.color.gray100};
  `};
`;

/**
 * Wraps a custom button and input to create a search form
 */
const SearchForm = styled.form`
  display: flex;
  height: ${HEIGHT}px;

  ${SearchFormButton} {
    order: 1;
    height: inherit;
    border-radius: ${HEIGHT / 2}px 0 0 ${HEIGHT / 2}px;
  }

  ${SearchFormInput} {
    order: 2;
    flex-grow: 1;
    height: inherit;
    border-radius: 0 ${HEIGHT / 2}px ${HEIGHT / 2}px 0;
  }
`;

export default SearchForm;
