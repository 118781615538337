import Flex from '@react-css/flex';
import useOnboardingStorage from 'hooks/useOnboardingStorage';
import { useCallback, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import FormField from 'storybookConfig/stories/cells/FormField';
import Body from 'storybookConfig/stories/molecules/Body';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybookConfig/stories/molecules/Button/SecondaryButton';
import TertiaryButton from 'storybookConfig/stories/molecules/Button/TertiaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import Input from 'storybookConfig/stories/molecules/Input';
import ProgressBar from 'storybookConfig/stories/organisms/ProgressBar';
import { Platform } from 'types/models/company';
import { SHOPIFY_APP_STORE_URL } from 'utils/constants';
import { friendlyPlatformName } from 'utils/platform';
import {
  BIGCOMMERCE_STORE_URL_REGEX,
  SHOPIFY_STORE_URL_REGEX,
  URL_FIELD_REGEX,
} from 'utils/strings';
import ResetShopifyLink from './ResetShopifyLink';

interface AddStoreUrlProps {
  currentStep: number;
  totalSteps: number;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
  isShopifyInstall: boolean;
}

const AddStoreUrl = ({
  currentStep,
  totalSteps,
  onNextButtonClick,
  onBackButtonClick,
  isShopifyInstall,
}: AddStoreUrlProps) => {
  const location = useLocation<{ isCreatingAnotherCompany?: boolean }>();
  const { setOnboardingStorage } = useOnboardingStorage();
  const { reset, watch, getValues, formState, register } = useFormContext();

  /**
   * Helpers
   */

  const isCreatingAnotherCompany = !!location.state?.isCreatingAnotherCompany;
  const commerceType = watch('commerceType');
  const platform = watch('platform');

  const fieldPattern = useMemo(() => {
    if (platform === Platform.Shopify) {
      return SHOPIFY_STORE_URL_REGEX;
    }

    if (platform === Platform.BigCommerce) {
      return BIGCOMMERCE_STORE_URL_REGEX;
    }

    return URL_FIELD_REGEX;
  }, [platform]);

  const fieldPlaceholder = useMemo(() => {
    if (platform === Platform.Shopify) {
      return 'https://store.myshopify.com';
    }

    if (platform === Platform.BigCommerce) {
      return 'https://store-id.bigcommerce.com';
    }

    return 'https://your.domain.com';
  }, [platform]);

  const openShopifyAppInstall = useCallback(() => {
    setOnboardingStorage({ currentStep, commerceType, platform, isCreatingAnotherCompany });
    window.open(SHOPIFY_APP_STORE_URL, '_self', 'noopener,noreferrer');
  }, [currentStep, commerceType, platform, isCreatingAnotherCompany, setOnboardingStorage]);

  const headerText = useMemo(() => {
    if (isShopifyInstall) {
      return 'Confirm your Shopify store URL';
    }
    if (platform === Platform.Shopify) {
      return 'Install the Modern Dropship App on Shopify';
    }
    return `What is your ${friendlyPlatformName(platform)} store URL?`;
  }, [isShopifyInstall, platform]);

  const body = useMemo(() => {
    if (isShopifyInstall) {
      return (
        <Body>
          Your company&apos;s information has been automatically imported from Shopify. Please
          verify that the information is correct. If not, <ResetShopifyLink />.
        </Body>
      );
    }
    if (platform === Platform.Shopify) {
      return (
        <Body>
          <SecondaryButton onClick={openShopifyAppInstall}>
            Connect your Shopify store to Modern Dropship
          </SecondaryButton>
        </Body>
      );
    }
    return <Body as="p">We use your store URL to connect your store to Modern Dropship.</Body>;
  }, [isShopifyInstall, openShopifyAppInstall, platform]);

  const onClickBack = useCallback(() => {
    if (!isShopifyInstall) {
      reset({
        ...getValues(),
        storeUrl: undefined,
      });
    }
    onBackButtonClick();
  }, [getValues, isShopifyInstall, onBackButtonClick, reset]);

  /**
   * Render
   */

  return (
    <Flex column gap="48px">
      <ProgressBar totalSteps={totalSteps} completedSteps={currentStep} />

      <Flex column gap="32px">
        <Flex column gap="8px">
          <Heading variant="Headings/H3" as="h1">
            {headerText}
          </Heading>

          {body}
        </Flex>

        <FormField
          id="storeUrl"
          labelText="Store URL"
          errorText={formState.errors?.storeUrl?.message as string}
          isRequired
        >
          <Input
            {...register('storeUrl', {
              required: true,
              pattern: {
                value: fieldPattern,
                message: `Please enter a valid store URL, e.g. ${fieldPlaceholder}`,
              },
            })}
            placeholder={fieldPlaceholder}
            readOnly={platform === Platform.Shopify}
            isFullWidth
          />
        </FormField>

        <Flex justifySpaceBetween>
          <TertiaryButton $iconName="navigate_before" $removePadding="left" onClick={onClickBack}>
            Back
          </TertiaryButton>

          <PrimaryButton
            $iconName="navigate_next"
            $iconAfter
            onClick={onNextButtonClick}
            disabled={!formState.isValid}
          >
            Next
          </PrimaryButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default AddStoreUrl;
