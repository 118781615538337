import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import { MessageContent } from 'storybookConfig/stories/species/Chat/Shared.styles';
import type { ChatMessage } from 'types/models/conversation';

const StyledMessageContent = styled(MessageContent)`
  color: ${({ theme }) => theme.color.gray500};
  max-width: 80%;
  padding: 0;
`;

interface ChatStatusMessageProps {
  content: ChatMessage['content'];
}

const ChatStatusMessage = ({ content }: ChatStatusMessageProps) => {
  return (
    <Flex justifyStart>
      <StyledMessageContent>{content}</StyledMessageContent>
    </Flex>
  );
};

export default ChatStatusMessage;
