import styled from '@emotion/styled';

export interface SupportLinkProps extends Partial<React.ComponentPropsWithoutRef<'a'>> {
  article: string;
}

const StyledLink = styled.a`
  color: ${({ theme }) => theme.color.bodyTextLinks};

  &:hover {
    color: ${({ theme }) => theme.color.bodyTextLinksHover};
    cursor: pointer;
  }
`;

/**
 * Glean the `article` prop from HelpDocs.io by logging into HelpDocs.io before
 * navigating to an article and clicking the Edit button at the bottom of the screen.
 */
const SupportLink = ({ article, ...linkProps }: SupportLinkProps) => {
  const href = `https://support.moderndropship.com/article/${article}`;

  return <StyledLink {...linkProps} as="a" href={href} target="_blank" rel="noopener noreferrer" />;
};

export default SupportLink;
