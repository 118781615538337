import Flex from '@react-css/flex';
import DOMPurify from 'dompurify';
import ChatAiMessage from 'storybookConfig/stories/species/Chat/ChatAiMessage';
import ChatAiMessageActions from 'storybookConfig/stories/species/Chat/ChatAiMessageActions';
import ChatAwaitingAiMessage from 'storybookConfig/stories/species/Chat/ChatAwaitingAiMessage';
import ChatErrorMessage from 'storybookConfig/stories/species/Chat/ChatErrorMessage';
import ChatHumanMessage from 'storybookConfig/stories/species/Chat/ChatHumanMessage';
import ChatStatusMessage from 'storybookConfig/stories/species/Chat/ChatStatusMessage';
import {
  WebsocketMessageType,
  type ChatMessage,
  type ConversationMessageFeedback,
} from 'types/models/conversation';

interface ChatMessagesProps {
  messages: ChatMessage[];
  isAwaitingWebsocketResponse?: boolean;
  onFeedbackFormSubmit: (messageId: string, feedback: ConversationMessageFeedback) => void;
  onInterruptButtonClick: () => void;
}

const ChatMessages = ({
  messages,
  isAwaitingWebsocketResponse,
  onFeedbackFormSubmit,
  onInterruptButtonClick,
}: ChatMessagesProps) => (
  <Flex column gap="24px">
    {messages.map((message) => {
      const content = DOMPurify.sanitize(message.content);

      switch (message.type) {
        case WebsocketMessageType.HUMAN:
          return <ChatHumanMessage key={message.key} content={content} />;
        case WebsocketMessageType.AI:
          return (
            <Flex column gap="8px" key={message.key}>
              <ChatAiMessage content={content} />

              {message.id && (
                <ChatAiMessageActions
                  onFeedbackFormSubmit={onFeedbackFormSubmit}
                  content={content}
                  messageId={message.id}
                />
              )}
            </Flex>
          );
        case WebsocketMessageType.STATUS:
          return <ChatStatusMessage key={message.key} content={content} />;
        case WebsocketMessageType.ERROR:
          return <ChatErrorMessage key={message.key} content={content} />;
        default:
          return null;
      }
    })}

    {isAwaitingWebsocketResponse && (
      <ChatAwaitingAiMessage onInterruptButtonClick={onInterruptButtonClick} />
    )}
  </Flex>
);

export default ChatMessages;
