import styled from '@emotion/styled';
import Flex from '@react-css/flex';
import { merge } from 'lodash';
import FormField from 'storybookConfig/stories/cells/FormField';

const Base = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const FieldGroup: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <Flex column gap="24px">
    {children}
  </Flex>
);

const HiddenInput = styled.input`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

interface HiddenFieldProps {
  name: string;
  value?: string;
}

const HiddenField = ({ name, value }: HiddenFieldProps) => {
  if (!value) return null;
  return <HiddenInput tabIndex={-1} aria-hidden readOnly type="text" name={name} value={value} />;
};

/**
 * Provides a consistent layout for Forms.
 */
export default merge(Base, {
  FieldGroup: styled(FieldGroup)``,
  Field: styled(FormField)``,
  HiddenField,
  Footer: styled(Flex)``,
});
