import { css } from '@emotion/react';
import theme from 'storybookConfig/stories/theme';

/**
 * Ensure that the values on this Enum correspond to the typography annotations in Figma.
 */
export enum TypographyVariants {
  // Headings

  H1 = 'Headings/H1',
  H2 = 'Headings/H2',
  H3 = 'Headings/H3',
  H4 = 'Headings/H4',

  // Body Text

  BodyRegular = 'Body/Regular',
  BodyRegularItalics = 'Body/Regular Italics',
  BodyRegularBold = 'Body/Regular Bold',
  BodyRegularUnderlined = 'Body/Regular Underlined',

  BodySmall = 'Body/Body Small',
  BodySmallItalics = 'Body/Body Small Italics',
  BodySmallBold = 'Body/Body Small Bold',
  BodySmallUnderlined = 'Body/Body Small Underlined',

  BodyHeader = 'Body/Header',
  BodyCaption = 'Body/Caption',

  // Navigation

  NavigationLink = 'Navigation/Nav Link',
  NavigationLinkActive = 'Navigation/Nav Link Active',

  // Buttons

  Button = 'Buttons/Button',
  ButtonDisabled = 'Buttons/Button Disabled',
  ButtonLight = 'Buttons/Button Light',
  ButtonLightDisabled = 'Buttons/Button Light Disabled',
  ButtonSmall = 'Buttons/Button Small',
  ButtonSmallDisabled = 'Buttons/Button Small Disabled',

  // Inputs

  Input = 'Inputs/Input',
  InputDisabled = 'Inputs/Input Disabled',
  InputMessage = 'Inputs/Input Message',

  // Chips

  ChipTextLarge = 'Chips/Chip Text Large',
  ChipTextLargeDisabled = 'Chips/Chip Text Large Disabled',
  ChipTextSmall = 'Chips/Chip Text Small',
  ChipTextSmallDisabled = 'Chips/Chip Small Disabled',

  // Other

  BadgeValue = 'Other/Badge Value',
}

export type TypographyVariant = `${TypographyVariants}`;

const headingTypography = (variant: TypographyVariant) => {
  switch (variant) {
    case TypographyVariants.H1:
      return css`
        font-family: ${theme.font.secondary};
        font-style: normal;
        font-weight: 600;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.01em;
      `;
    case TypographyVariants.H2:
      return css`
        font-family: ${theme.font.secondary};
        font-style: normal;
        font-weight: 400;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: -0.01em;
      `;
    case TypographyVariants.H3:
      return css`
        font-family: ${theme.font.secondary};
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        letter-spacing: 0.01em;
      `;
    case TypographyVariants.H4:
      return css`
        font-family: ${theme.font.secondary};
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        letter-spacing: 0.01em;
      `;
    default:
      return css``;
  }
};

const navigationTypography = (variant: TypographyVariant) => css`
  font-family: ${theme.font.primary};
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;

  ${variant === TypographyVariants.NavigationLinkActive &&
  css`
    font-weight: 500;
  `};
`;

const buttonTypography = (variant: TypographyVariant) => css`
  font-family: ${theme.font.secondary};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-transform: capitalize;

  ${variant.match(/Disabled$/) &&
  css`
    font-style: italic;
  `}

  ${variant.match(new RegExp(`^${TypographyVariants.ButtonLight}`)) &&
  css`
    font-weight: 400;
    letter-spacing: -0.01em;
  `}

  ${variant.match(new RegExp(`^${TypographyVariants.ButtonSmall}`)) &&
  css`
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.02em;
    text-transform: capitalize;
  `}
`;

const bodyCopyTypography = (variant: TypographyVariant) => {
  if (variant === TypographyVariants.BodyHeader) {
    return css`
      font-family: ${theme.font.secondary};
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.08em;
    `;
  }

  if (variant === TypographyVariants.BodyCaption) {
    return css`
      font-family: ${theme.font.primary};
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: -0.01em;
    `;
  }

  return css`
    font-family: ${theme.font.primary};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;

    ${variant.match(new RegExp(`^${TypographyVariants.BodySmall}`)) &&
    css`
      font-size: 14px;
      line-height: 22px;
    `}

    ${variant.match(/Italics$/) &&
    css`
      font-style: italic;
    `}

  ${variant.match(/Bold$/) &&
    css`
      font-weight: 700;
    `}

  ${variant.match(/Underlined$/) &&
    css`
      text-decoration-line: underline;
    `}
  `;
};

const inputTypography = (variant: TypographyVariant) => {
  switch (variant) {
    case TypographyVariants.Input:
    case TypographyVariants.InputDisabled:
      return css`
        font-family: ${theme.font.primary};
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        ${variant === TypographyVariants.InputDisabled &&
        css`
          font-style: italic;
        `}
      `;
    case TypographyVariants.InputMessage:
      return css`
        font-family: ${theme.font.primary};
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: -0.01em;
      `;
    default:
      return css``;
  }
};

const chipsTypography = (variant: TypographyVariant) => css`
  font-family: ${theme.font.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: -0.01em;

  ${variant.match(/Disabled$/) &&
  css`
    font-style: italic;
  `}

  ${variant.match(new RegExp(`^${TypographyVariants.ChipTextLarge}`)) &&
  css`
    font-size: 14px;
    line-height: 14px;
  `}
`;

const otherTypography = (variant: TypographyVariant) => {
  switch (variant) {
    case TypographyVariants.BadgeValue:
      return css`
        font-family: ${theme.font.monospace};
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: -0.01em;
      `;
    default:
      return css``;
  }
};

/**
 * A helper to apply proper typography to any Styled Component.
 */
const typography = (variant: TypographyVariant) => {
  if (variant.match(/^Headings/)) return headingTypography(variant);
  if (variant.match(/^Navigation/)) return navigationTypography(variant);
  if (variant.match(/^Button/)) return buttonTypography(variant);
  if (variant.match(/^Body/)) return bodyCopyTypography(variant);
  if (variant.match(/^Inputs/)) return inputTypography(variant);
  if (variant.match(/^Chips/)) return chipsTypography(variant);
  if (variant.match(/^Other/)) return otherTypography(variant);

  return css``;
};

export default typography;
