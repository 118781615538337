import SideNavLayout from 'components/Dashboard/SideNavLayout';
import { Suspense } from 'react';
import { Redirect, Route, type RouteProps } from 'react-router-dom';
import { useAppSelector } from 'store';
import { selectUser } from 'store/selectors/me/user';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  showNav?: boolean;
}

const PrivateRoute = ({
  component: Component,
  showNav = true,
  ...routeProps
}: PrivateRouteProps) => {
  const user = useAppSelector(selectUser);
  if (!user) return <Redirect to="/signin" />;

  // Redirect to onboarding if user has no memberships and not currently onboarding
  if (user?.memberships?.length === 0 && routeProps.path !== '/onboarding') {
    return <Redirect to="/onboarding" />;
  }

  return (
    <Route
      {...routeProps}
      render={(props) =>
        showNav ? (
          <SideNavLayout>
            <Suspense fallback={null}>
              <Component {...props} />
            </Suspense>
          </SideNavLayout>
        ) : (
          <Suspense fallback={null}>
            <Component {...props} />
          </Suspense>
        )
      }
    />
  );
};

export default PrivateRoute;
