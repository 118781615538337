import { css } from '@emotion/react';
import styled from '@emotion/styled';

import typography from 'storybookConfig/mixins/typography';

export enum BadgeVariants {
  Info = 'info',
  Error = 'error',
}

const Wrapper = styled.span<BadgeProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  padding: 3px 5px;
  min-width: 18px;
  max-height: 18px;
  overflow: hidden;
  border-radius: 9px;

  ${typography('Other/Badge Value')};
  color: ${({ theme }) => theme.color.white};

  ${({ theme, variant = BadgeVariants.Info }) => {
    if (variant === BadgeVariants.Info)
      return css`
        background: ${theme.color.info500};
      `;

    if (variant === BadgeVariants.Error)
      return css`
        background: ${theme.color.error500};
      `;

    return css``;
  }}
`;

export interface BadgeProps {
  /**
   * The type of badge to display
   */
  variant?: `${BadgeVariants}`;
  /**
   * A numeric count to display inside the Badge.
   * If the number is larger than 99, the Badge will display "99+".
   */
  count?: number;
}

/**
 * Displays a colored dot containing a number.
 */
const Badge = ({ count = 0, ...rest }: BadgeProps) => {
  if (count < 1) return null;
  const text = count > 99 ? '99+' : count.toString();
  return <Wrapper {...rest}>{text}</Wrapper>;
};

export default Badge;
