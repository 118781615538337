import { FloatingChatContext } from 'containers/App/Contexts/FloatingChatContext';
import { useContext } from 'react';
import Button from 'storybookConfig/stories/molecules/Button';
import Icon from 'storybookConfig/stories/molecules/Icon';

const FloatingChatHeaderActions = () => {
  const { isWindowExpanded, collapseWindow, expandWindow } = useContext(FloatingChatContext);

  return (
    <Icon
      as={Button}
      name={isWindowExpanded ? 'close_fullscreen' : 'open_in_full'}
      size="20px"
      onClick={isWindowExpanded ? collapseWindow : expandWindow}
      color="white"
    />
  );
};

export default FloatingChatHeaderActions;
