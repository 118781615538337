import { css } from '@emotion/react';
import styled from '@emotion/styled';
import {
  FloatingChatContext,
  type FloatingChatContextValue,
} from 'containers/App/Contexts/FloatingChatContext';
import { useContext } from 'react';
import elevate from 'storybookConfig/mixins/elevate';
import Chat from 'storybookConfig/stories/species/Chat';
import { CHAT_FORM_MIN_HEIGHT } from 'storybookConfig/stories/species/Chat/ChatForm';
import { CHAT_HEADER_HEIGHT } from 'storybookConfig/stories/species/Chat/ChatHeader';
import { BUTTON_SIZE } from 'storybookConfig/stories/species/Chat/FloatingChat/FloatingChatButton';

export const WINDOW_OFFSET = 24;

type WrapperProps = Pick<FloatingChatContextValue, 'isWindowExpanded'>;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: calc(${BUTTON_SIZE}px + ${WINDOW_OFFSET}px);
  right: 0;
  width: 400px;
  height: 600px;
  ${elevate('4')};
  border-radius: 8px;
  overflow: hidden;
  background: ${({ theme }) => theme.color.white};

  ${({ isWindowExpanded }) =>
    isWindowExpanded &&
    css`
      top: 0;
      left: 0;
      width: auto;
      height: auto;
    `}

  ${Chat} {
    height: 600px;

    ${({ isWindowExpanded }) =>
      isWindowExpanded &&
      css`
        height: 100%;
      `}
  }

  ${Chat.Body} {
    height: calc(100% - ${CHAT_HEADER_HEIGHT}px - ${CHAT_FORM_MIN_HEIGHT}px);
  }
`;

interface FloatingChatWindowProps {
  children: React.ReactNode;
}

const FloatingChatWindow = ({ children }: FloatingChatWindowProps) => {
  const { isWindowOpen, isWindowExpanded } = useContext(FloatingChatContext);

  if (!isWindowOpen) return null;

  return <Wrapper isWindowExpanded={isWindowExpanded}>{children}</Wrapper>;
};

export default FloatingChatWindow;
