import Flex from '@react-css/flex';
import Spinner from 'storybookConfig/stories/molecules/Spinner';

export const CHAT_LOADING_SIZE = 80;

const ChatLoading = () => (
  <Flex justifyCenter>
    <Spinner size={`${CHAT_LOADING_SIZE}px`} />
  </Flex>
);

export default ChatLoading;
