import { createRoot } from 'react-dom/client';
import '@fontsource-variable/material-symbols-rounded/full.css';
import 'simplebar-react/dist/simplebar.min.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import '@fontsource/montserrat/400-italic.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600-italic.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/source-code-pro/600.css';
import App from 'containers/App/App';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
