import Flex from '@react-css/flex';
import ArrowLeftSvg from 'images/arrow_left.svg';
import ArrowRightSvg from 'images/arrow_right.svg';
import DomainSvg from 'images/domain.svg';
import ShoppingBasketSvg from 'images/shopping_basket.svg';
import TrolleySvg from 'images/trolley.svg';
import WarehouseSvg from 'images/warehouse.svg';
import type React from 'react';
import Body from 'storybookConfig/stories/molecules/Body';
import DecisionButton from 'storybookConfig/stories/molecules/Button/DecisionButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import { CommerceType } from 'types/models/company';

interface ContentProps {
  imageNode: React.ReactNode;
  heading: string;
  text: string;
}

const Content: React.FC<ContentProps> = ({ imageNode, heading, text }) => {
  return (
    <Flex column alignItemsCenter gap="16px">
      {imageNode}

      <Flex column gap="8px">
        <Heading variant="Headings/H4" as="h2" align="center">
          {heading}
        </Heading>

        <Body variant="Body/Body Small" color="bodyTextSecondary" align="center">
          {text}
        </Body>
      </Flex>
    </Flex>
  );
};

interface CommerceTypeDecisionButtonProps extends React.ComponentProps<typeof DecisionButton> {
  variant: CommerceType;
}

const CommerceTypeDecisionButton: React.FC<CommerceTypeDecisionButtonProps> = ({
  variant,
  ...rest
}) => {
  if (variant === CommerceType.Seller)
    return (
      <DecisionButton {...rest}>
        <Content
          imageNode={
            <Flex gap="8px" alignItemsCenter>
              <img src={TrolleySvg} alt="Trolly icon" width="64" height="64" />
              <img src={ArrowRightSvg} alt="Arrow right icon" width="32" height="32" />
              <img src={DomainSvg} alt="Domain icon" width="64" height="64" />
            </Flex>
          }
          heading="Selling to retailer"
          text="You might call yourself a supplier, vendor, or wholesaler."
        />
      </DecisionButton>
    );

  if (variant === CommerceType.Buyer)
    return (
      <DecisionButton {...rest}>
        <Content
          imageNode={
            <Flex gap="8px" alignItemsCenter>
              <img src={WarehouseSvg} alt="Warehouse icon" width="64" height="64" />
              <img src={ArrowLeftSvg} alt="Arrow left icon" width="32" height="32" />
              <img src={ShoppingBasketSvg} alt="Shopping icon" width="64" height="64" />
            </Flex>
          }
          heading="Buying from vendor"
          text="You might call yourself a retailer, distributor, or marketplace."
        />
      </DecisionButton>
    );

  return null;
};

export default CommerceTypeDecisionButton;
