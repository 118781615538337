import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Flex from '@react-css/flex';

import Card from 'storybookConfig/stories/cells/Card';
import Body from 'storybookConfig/stories/molecules/Body';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybookConfig/stories/molecules/Button/SecondaryButton';
import Icon from 'storybookConfig/stories/molecules/Icon';

const ICON_SIZE = 56;

export enum AnnouncementKinds {
  Action = 'action',
  Feature = 'feature',
}

type IconWrapperProps = Pick<AnnouncementProps, 'kind'>;

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  border-radius: 100%;

  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ kind, theme }) => {
    switch (kind) {
      case AnnouncementKinds.Action:
        return css`
          background-color: ${theme.color.error100};
        `;
      case AnnouncementKinds.Feature:
        return css`
          background-color: ${theme.color.blue050};
        `;
      default:
        return css``;
    }
  }}
`;

const CloseIcon = styled(Icon)`
  position: absolute;
  top: 6px;
  right: 6px;

  &:hover {
    cursor: pointer;
  }
`;

const Title = styled.strong`
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  font-family: ${({ theme }) => theme.font.secondary};
`;

type WrapperProps = Pick<AnnouncementProps, 'kind'>;

const Wrapper = styled(Card)<WrapperProps>`
  position: relative;
  margin-top: ${ICON_SIZE / 2}px;

  width: 200px;
  max-width: 100%;

  color: ${({ theme }) => theme.color.white};
  border-radius: 8px;
  padding: ${ICON_SIZE / 2 + 16}px 16px 16px;

  ${({ kind, theme }) => {
    switch (kind) {
      case AnnouncementKinds.Action:
        return css`
          background: ${theme.color.blue900};
          background-blend-mode: overlay, normal;
        `;
      case AnnouncementKinds.Feature:
        return css`
          background: radial-gradient(
            182.88% 182.88% at 0% 100%,
            #50e3c2 0%,
            #228aff 56.77%,
            #124987 100%
          );
          background-blend-mode: overlay, normal;
        `;
      default:
        return css``;
    }
  }};
`;

interface AnnouncementProps {
  /**
   * The kind of announcement
   */
  kind: `${AnnouncementKinds}`;
  /**
   * The body of the announcement's message
   */
  children: React.ReactNode;
  /**
   * The text displayed inside the card's Button
   */
  ctaButtonText: string;
  /**
   * The event that the Button will trigger
   */
  onCtaButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * The event that the Button will trigger
   */
  onCloseButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * The title that will appear on the new-feature cards
   */
  title: string;
  /**
   * Allows styling
   */
  className?: string;
}

const Announcement = ({
  kind,
  children,
  ctaButtonText,
  onCtaButtonClick,
  onCloseButtonClick,
  title,
  className,
}: AnnouncementProps) => {
  const shouldShowCloseButton = !!onCloseButtonClick;

  return (
    <Wrapper kind={kind} className={className} role="alert">
      {shouldShowCloseButton && (
        <CloseIcon
          as="button"
          onClick={onCloseButtonClick}
          size="20px"
          color="white"
          name="cancel"
          aria-label="Dismiss announcement"
        />
      )}

      {kind === AnnouncementKinds.Action && (
        <>
          <IconWrapper kind={kind}>
            <Icon name="priority_high" size="36px" color="error500" />
          </IconWrapper>

          <Flex column gap="16px">
            <Title>{title}</Title>

            <Body variant="Body/Body Small" align="center">
              {children}
            </Body>

            <Flex justifySpaceAround>
              <PrimaryButton kind="destructive" size="small" onClick={onCtaButtonClick}>
                {ctaButtonText}
              </PrimaryButton>
            </Flex>
          </Flex>
        </>
      )}

      {kind === AnnouncementKinds.Feature && (
        <>
          <IconWrapper kind={kind}>
            <Icon name="campaign" size="36px" color="blue400" />
          </IconWrapper>

          <Flex column gap="16px">
            <Title>{title}</Title>

            <Body variant="Body/Body Small" align="center">
              {children}
            </Body>

            <Flex justifySpaceAround>
              <SecondaryButton size="small" onClick={onCtaButtonClick}>
                {ctaButtonText}
              </SecondaryButton>
            </Flex>
          </Flex>
        </>
      )}
    </Wrapper>
  );
};

export default Announcement;
