import Flex from '@react-css/flex';
import type * as Sentry from '@sentry/react';
import Card from 'storybookConfig/stories/cells/Card';
import Body from 'storybookConfig/stories/molecules/Body';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import SecondaryButton from 'storybookConfig/stories/molecules/Button/SecondaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import Layout from 'storybookConfig/stories/species/Layout';

const ErrorSuggestion = ({ message }: { message: string }) => {
  // For https://convictional.sentry.io/issues/4709478990/
  if (message.includes("Failed to execute 'removeChild' on 'Node'")) {
    return (
      <Body as="p" color="error700" align="center">
        We&apos;ve noticed that this type of error is thrown when Google Chrome&apos;s Translate
        Page feature is in use.
        <br />
        It might be worth trying another browser, another translation tool, or disable the
        translation feature.
      </Body>
    );
  }

  return null;
};

const AppError: Sentry.FallbackRender = ({ error, resetError }) => {
  const message = (error as Error)?.message ?? '';
  return (
    <Layout.Root>
      <Layout.Content>
        <Flex column gap="32px" alignItemsCenter>
          <Layout.Logo />
          <Card padding="48px">
            <Heading variant="Headings/H1" align="center">
              Something went wrong!
            </Heading>

            <Body as="p" align="center">
              Rest assured, we&apos;ve been notified and are looking into this error:
              <br />
              <code>{message}</code>
            </Body>

            <ErrorSuggestion message={message} />

            <Flex justifyCenter gap="16px">
              <SecondaryButton as="a" href="mailto:support@convictional.com">
                Email Support
              </SecondaryButton>

              <PrimaryButton onClick={resetError}>Reset Error</PrimaryButton>
            </Flex>
          </Card>
        </Flex>
      </Layout.Content>
    </Layout.Root>
  );
};

export default AppError;
