import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { forwardRef } from 'react';

import elevate from 'storybookConfig/mixins/elevate';
import typography from 'storybookConfig/mixins/typography';

const RADIO_SIZE = 20;

type LabelWrapperProps = Pick<RadioButtonProps, 'disabled'>;

const LabelWrapper = styled.label<LabelWrapperProps>`
  display: inline-flex;
  gap: 8px;

  ${typography('Navigation/Nav Link')};

  ${({ theme, disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
          color: ${theme.color.bodyTextDisabled};
          font-style: italic;
        `
      : css`
          cursor: pointer;
          color: ${theme.color.bodyTextPrimary};
        `}
`;

const RadioInput = styled.input`
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: ${RADIO_SIZE}px;
  height: ${RADIO_SIZE}px;
  border-radius: 100%;
  transform: translateY(-${RADIO_SIZE / 10}px);
  display: grid;
  place-content: center;

  ${({ disabled }) => css`
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `};

  ${({ theme }) => css`
    border: 2px solid ${theme.color.gray300};
  `};

  &::before {
    content: '';
    width: ${RADIO_SIZE - 8}px;
    height: ${RADIO_SIZE - 8}px;
    transform: scale(0);
    border-radius: 100%;

    ${({ theme }) => css`
      background-color: ${theme.color.blue500};
    `};
  }

  &:checked {
    ${elevate('1')};

    ${({ theme }) => css`
      background-color: ${theme.color.white};
      border-color: ${theme.color.blue500};
    `};

    &::before {
      transform: scale(1);
    }
  }
`;

export interface RadioButtonProps extends React.ComponentProps<typeof RadioInput> {
  /**
   * The name of the form control
   */
  name: string;
  /**
   * The text accompanying the button
   */
  children?: React.ReactNode;
}

/**
 * An accessible radio button control. Additional props passed directly to the underlying styled radio `input`.
 */
const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ name, children, className, disabled, ...rest }, ref) => (
    <LabelWrapper className={className} disabled={disabled}>
      <RadioInput type="radio" name={name} ref={ref} disabled={disabled} {...rest} />
      <span>{children}</span>
    </LabelWrapper>
  )
);

export default RadioButton;
