import Flex from '@react-css/flex';

import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { useAppSelector } from 'store';
import { selectUserMembershipNames } from 'store/selectors/me/user';
import Body from 'storybookConfig/stories/molecules/Body';
import PrimaryButton from 'storybookConfig/stories/molecules/Button/PrimaryButton';
import TertiaryButton from 'storybookConfig/stories/molecules/Button/TertiaryButton';
import Heading from 'storybookConfig/stories/molecules/Heading';
import Input from 'storybookConfig/stories/molecules/Input';
import SupportLink from 'storybookConfig/stories/molecules/Link/SupportLink';
import Form from 'storybookConfig/stories/organisms/Form';
import ProgressBar from 'storybookConfig/stories/organisms/ProgressBar';
import { URL_FIELD_REGEX } from 'utils/strings';
import ResetShopifyLink from './ResetShopifyLink';

interface HelpTextProps {
  isShopifyInstall: boolean;
}

const HelpText: React.FC<HelpTextProps> = ({ isShopifyInstall }) => {
  const userMembershipNames = useAppSelector(selectUserMembershipNames);

  if (isShopifyInstall) {
    return (
      <Body>
        Your company&apos;s information has been automatically imported from Shopify. Please verify
        that the information is correct. If not, <ResetShopifyLink />.
      </Body>
    );
  }

  if (userMembershipNames.length > 0) {
    return (
      <Body>
        For your reference, you are currently a member of the following companies:
        <br />
        {userMembershipNames.join(', ')}
      </Body>
    );
  }

  return (
    <Body>
      Does your company already have a Modern Dropship account? Ask a teammate to{' '}
      <SupportLink article="dvzdivcsdu">add you as a Team Member</SupportLink>.
    </Body>
  );
};

interface CompanyFieldsProps {
  currentStep: number;
  totalSteps: number;
  isShopifyInstall: boolean;
  onNextButtonClick: () => void;
  onBackButtonClick: () => void;
}

const CompanyFields: React.FC<CompanyFieldsProps> = ({
  currentStep,
  totalSteps,
  isShopifyInstall,
  onNextButtonClick,
  onBackButtonClick,
}) => {
  const { register, formState, getValues, reset } = useFormContext();
  const location = useLocation<{ isCreatingAnotherCompany?: boolean }>();

  /**
   * Helpers
   */

  const isCreatingAnotherCompany = !!location.state?.isCreatingAnotherCompany;

  const onClickBack = useCallback(() => {
    if (!isShopifyInstall) {
      reset({
        ...getValues(),
        companyUrl: '',
        companyName: '',
      });
    }
    onBackButtonClick();
  }, [getValues, isShopifyInstall, onBackButtonClick, reset]);

  /**
   * Render
   */

  return (
    <Flex column gap="48px">
      <ProgressBar totalSteps={totalSteps} completedSteps={currentStep} />

      <Flex column gap="32px">
        <Flex column gap="8px">
          <Heading variant="Headings/H3" as="h1">
            Let&apos;s set up {isCreatingAnotherCompany ? 'another' : 'your'} company profile
          </Heading>

          <HelpText isShopifyInstall={isShopifyInstall} />
        </Flex>

        <Form.FieldGroup>
          <Form.Field
            labelText="Company Name"
            isRequired
            id="companyName"
            errorText={formState.errors.companyName?.message as string}
          >
            <Input
              {...register('companyName', { required: "Please enter your company's name" })}
              placeholder="The Widget Company"
              readOnly={isShopifyInstall}
              isFullWidth
            />
          </Form.Field>

          <Form.Field
            labelText="Company URL"
            isRequired
            id="companyUrl"
            errorText={formState.errors.companyUrl?.message as string}
          >
            <Input
              {...register('companyUrl', {
                required: "Please enter your company's url",
                pattern: {
                  value: URL_FIELD_REGEX,
                  message: 'Please enter a valid URL',
                },
              })}
              placeholder="widgetcompany.com"
              readOnly={isShopifyInstall}
              isFullWidth
            />
          </Form.Field>
        </Form.FieldGroup>
        <Form.Footer justifySpaceBetween>
          <TertiaryButton $removePadding="left" onClick={onClickBack}>
            Back
          </TertiaryButton>

          <PrimaryButton
            $iconName="navigate_next"
            $iconAfter
            disabled={!formState.isValid}
            onClick={onNextButtonClick}
          >
            Next
          </PrimaryButton>
        </Form.Footer>
      </Flex>
    </Flex>
  );
};

export default CompanyFields;
