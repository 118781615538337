import { css, type Theme } from '@emotion/react';
import styled from '@emotion/styled';
import type { LinkProps } from 'react-router-dom';

import type { ButtonProps } from 'storybookConfig/stories/molecules/Button';
import Button, {
  ButtonKinds,
  ButtonSizes,
  getButtonPadding,
  getButtonTypography,
} from 'storybookConfig/stories/molecules/Button';

type GetButtonColorSchemeArg = Pick<TertiaryButtonProps, '$iconAfter' | 'kind'>;

const getButtonColorScheme = ({
  $iconAfter,
  kind = ButtonKinds.Action,
  theme,
}: GetButtonColorSchemeArg & { theme: Theme }) => {
  if (kind === ButtonKinds.Action) {
    return css`
      color: ${theme.color.bodyTextLinks};

      &:hover,
      &:focus {
        color: ${theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.gray700};
      }
    `;
  }

  if (kind === ButtonKinds.Neutral) {
    return css`
      color: ${theme.color.bodyTextSecondary};

      &:hover,
      &:focus {
        color: ${theme.color.gray700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.gray700};
      }
    `;
  }

  if (kind === ButtonKinds.Destructive) {
    return css`
      color: ${theme.color.error500};

      &:hover,
      &:focus {
        color: ${theme.color.error700};
      }

      &:focus::${$iconAfter ? 'before' : 'after'} {
        border-color: ${theme.color.error700};
      }
    `;
  }

  return css``;
};

export interface TertiaryButtonProps extends ButtonProps {
  /**
   * The kind of action this button describes
   */
  kind?: `${ButtonKinds}`;
  /**
   * Remove padding
   */
  $removePadding?: 'left' | 'right';

  /**
   * The HTML element to render the button as
   */
  as?: React.ElementType;

  /**
   * an optional route to navigate to if cast as a Link
   */
  to?: LinkProps['to'];
  /**
   * an optional article route to navigate to if cast as a SupportLink
   */
  article?: string;
}

/**
 * A composed `Button` component that represents the primary action a user can take on a page.
 *
 * This button provides padding consistent with the `PrimaryButton` and `SecondaryButton` components for layout purposes.
 *
 * - `size` is defaulted to `ButtonSizes.Large`
 * - `kind` is defaulted to `ButtonKinds.Action`
 */
const TertiaryButton = styled(Button, {
  shouldForwardProp: (prop) => !['kind', '$removePadding', 'as', '$iconName'].includes(prop),
})<TertiaryButtonProps>`
  position: relative;
  background: transparent;

  /* Determine appropriate typography */
  ${({ size = ButtonSizes.Large, disabled }) => getButtonTypography({ size, disabled })}

  /* Determine appropriate padding */
  ${({ size = ButtonSizes.Large, $iconName, $iconAfter }) =>
    getButtonPadding({ size, $iconName, $iconAfter })}

  /* Determine color scheme */
  ${({ kind, $iconAfter, theme }) => getButtonColorScheme({ kind, $iconAfter, theme })}

  /* Maintain a static disabled state */
  &:disabled {
    color: ${({ theme }) => theme.color.bodyTextDisabled};
    border-color: ${({ theme }) => theme.color.bodyTextDisabled};
  }

  /* Remove padding */
  ${({ $removePadding }) =>
    $removePadding === 'left' &&
    css`
      padding-left: 0;
    `}

  ${({ $removePadding }) =>
    $removePadding === 'right' &&
    css`
      padding-right: 0;
    `}
`;

export default TertiaryButton;
